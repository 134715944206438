// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs"

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import app1 from '../../assets/app_illust/app1.png';
import app2 from '../../assets/app_illust/app2.png';
import app3 from '../../assets/app_illust/app3.png';
import app4 from '../../assets/app_illust/app4.png';
import app5 from '../../assets/app_illust/app5.png';
import app6 from '../../assets/app_illust/app6.png';
import app7 from '../../assets/app_illust/app7.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class AppIllustProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/app_illust_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#D473D1'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">App Illustration</h1>
          <h2 className="h2">
          While I am primarily a UX designer, I have found myself in many situations where
          a client has needed custom graphics for their product. Below, you can find a
          few of the illustrations I've created for mobile and web app projects.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["Illustration", "UI/UX"])}
          </div>


          {/* Background */}
          <h2 className="h2"> Unmasked </h2>
          <p className='p'><a href="https://www.unmaskedproject.com/" style={{color: this.accent_color}}> Unmasked</a> is an anonymous social media app for college students
          focused on mental health. These illustrations were created for the app's onboarding process and dashboard. Background and logo designs revamped from the originals
          by Theia Qu. <a href="/unmasked" style={{color: this.accent_color}}>Read the full case study here</a>.
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={app4}></img>
          </div>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={app3}></img>
          </div>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={app5}></img>
          </div>

          <h2 className="h2"> SoundBoard </h2>
          <p className="p"><a href="https://soundboard.carrd.co/" style={{color: this.accent_color}}> SoundBoard </a>
          is an app designed for sound designers and audio enthusiasts alike.
          These illustrations were created for the logo, user avatars, and log-in screen. <a href="/soundboard" style={{color: this.accent_color}}>Read the full case study here</a>.
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={app1}></img>
          </div>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={app2}></img>
          </div>

          <h2 className="h2"> Quest AI </h2>
          <p className="p"><a href="https://www.quest.ai/" style={{color: this.accent_color}}> Quest </a>
          is the easiest way to build frontend. Build react components & MUI automatically from your Figma designs. I created these illustrations as a part of an example template for users to view. <a href="/questai" style={{color: this.accent_color}}>Read the full case study here</a>.
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={app6}></img>
          </div>


          <h2 className="h2"> Flourish </h2>
          <p className="p"><a href="https://www.flourish.care/" style={{color: this.accent_color}}> Flourish</a> is an app designed to help patients with multi-symptom chronic illnesses better track,
          visualize, and communicate their symptoms and triggers over time. These illustrations were created for the app's website and for different parts of the app. <a href="/flourish" style={{color: this.accent_color}}>Read the full case study here</a>.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={app7}></img>
          </div>



          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default AppIllustProjectPage;
