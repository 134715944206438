const SMALLEST = 300;
const SMALLER = 400;
const LARGER = 550;

export function itemSize() {
    let {innerWidth: width} = window;
    if(width < 1300) {
        return SMALLEST;
    } else if(width < 1800) {
        return SMALLER;
    } else {
        return LARGER;
    }
}

export function scale() {
    return itemSize() / LARGER;
}