// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import designs from '../../assets/soundboard/soundboard_mocks.png';
import sb1 from '../../assets/soundboard/sb1.png';
import sb2 from '../../assets/soundboard/sb2.png';
import sb3 from '../../assets/soundboard/sb3.jpeg';
import sb4 from '../../assets/soundboard/sb4.jpeg';
import sb5 from '../../assets/soundboard/sb5.jpeg';
import sb6 from '../../assets/soundboard/sb6.png';
import sb7 from '../../assets/soundboard/soundboard_mocks.png';
import sb8 from '../../assets/soundboard/sb8.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class SoundboardProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/soundboard_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#BE84BA'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark"/>
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}}/>
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Soundboard</h1>
          <h2 className="h2">
          <a target="_blank" href="https://soundboard.carrd.co/" style={{color: this.accent_color}}> SoundBoard </a>
          is an app designed for sound designers and audio enthusiasts alike. The app is available for download as
          an <a target="_blank" href="https://drive.google.com/file/d/12BD2jfos_-WVrGxTgKtobTN7BQFHC7x4/view" style={{color: this.accent_color}}>Android .apk</a>.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["UI/UX", "Development", "Graphic Design", "Illustration"])}
          </div>

          {/* Background */}
          <h2 className='h2'>Overview</h2>
          <p className='p'>
          Soundboard is an android app developed in Kotlin with a Firebase database. The app created
          as a part of Dartmouth's Smartphone/Android Programming Course (CS 165). Over 3 weeks, I worked on the app's UI/UX
          and implemented most of the app's visual frontend. I also created all of the graphic design and illustration works associated
          with the project. You can download
          the app .apk and read more about the development details <a target="_blank" href="https://soundboard.carrd.co/" style={{color: this.accent_color}}>here</a>.
          </p>

           {/* Graphical list idk */}
           <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["October – November 2021 \n(3 weeks)",
                         "Developer",
                         "Kotlin\nFirebase\nXML\nAndroid\nPrototyping\nFigma",
                         "Mira Ram\nXuedan Zou\nZihan (Anna) Wu\nTrevor Van de Velde"]}
          />

          {/* Background */}
          <h2 className='h2'>The Problem</h2>
          <p className='p'>
          Designers who work with visual mediums (e.g. UX, graphic, illustration) have access to a multitude
          of platforms and social media through which they can find inspiration and references for their work.
          Sites such as Pinterest and Dribbble allow visual designers to sort through a user-generated database
          of content to help inform their own work. My team noticed a gap in the market for such a service
          for sound designers who frequently need to access large databases of soundbites to sample.
          </p>

          <p className='p'>
          For this 3-week development sprint, my team's solution was
          <a target="_blank" href="https://soundboard.carrd.co/" style={{color: this.accent_color}}> SoundBoard</a>. At a high level,
          Soundboard would allow users to discover soundbites uplodated by other users and curate their own soundboards.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={sb1}></img>
          </div>

          <ProjectAnnouncementComponent
            color = {this.accent_color}
            title = "THE GOAL"
            text = {`How might we improve the process of finding sound samples and musical inspiration for sound designers?`}
          />

          <h2 className='h2'>Soundbyte</h2>
          <p className='p'>
          At soundboard, we call each post a <b style={{color: this.accent_color}}>soundbyte</b> in order to combine the typical musical nature of soundbites
          with the more technical nature of sound design and sound engineering. And with that out of the way, a feature overview:
          </p>
          <br/>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={sb2}></img>
          </div>
          <h2 className='h2'>Features</h2>
          <h3 className='h3'>Home/Dashboard Page</h3>
          <p className='p'>
          View a feed of soundbytes created by other users
          </p>

          <h3 className='h3'>Discover Page Page</h3>
          <p className='p'>
          Search for soundbytes created by other users by title, tag, or author
          </p>

          <h3 className='h3'>Post a Soundbyte</h3>
          <p className='p'>
          Users can upload their own soundbytes to share with others
          </p>

          <h3 className='h3'>Profile Page</h3>
          <p className='p'>
          Displays user-curated soundboards. User credentials are connected to a settings page
          </p>
          <br/>

          <h2 className='h2'>How it works</h2>
          <h3 className='h3'>MVVM Diagram</h3>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={sb3}></img>
          </div>
          <br/><br/>

          <h3 className='h3'>Threaded Design Diagram</h3>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={sb4}></img>
          </div>
          <br/><br/>

          <h3 className='h3'>Outside Libraries</h3>
          <p className='p'>
          We also used various outside libraries and integrations, including:
          </p>
          <ul className='ul'>
            <li className='li'><a target="_blank" href="https://firebase.google.com/" style={{color: this.accent_color}}>Firebase</a></li>
            <li className='li'><a target="_blank" href="https://github.com/GautamChibde/android-audio-visualizer" style={{color: this.accent_color}}>Audio Visualizer</a></li>
            <li className='li'><a target="_blank" href="https://developer.android.com/reference/android/media/MediaPlayer" style={{color: this.accent_color}}>Media Player</a> </li>
            <li className='li'><a target="_blank" href="https://github.com/whilu/AndroidTagView" style={{color: this.accent_color}}>Tags</a> </li>
            <li className='li'><a target="_blank" href="https://github.com/square/picasso" style={{color: this.accent_color}}>Picasso</a> </li>
          </ul>
          <br/>

          <h2 className='h2'>UI/UX design</h2>
          <p className='p'>
          Over 3 weeks where the vast majority of time needed to be devoted to the app's development, we had very little
          time to devote to polishing the design and dive into the design process. Even so, I did spend some time
          creating wireframes and iterating on the app's visual design:
          </p>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'row'}}>
            <img style={{minHeight:'10vh',maxHeight:'28vw', display:'inline-block', padding:'5px'}} src={sb5}></img>
            <img style={{minHeight:'10vh',maxHeight:'28vw', display:'inline-block',padding:'5px'}} src={sb6}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={sb7}></img>
          </div>
          <br/>

          <h2 className='h2'>Final Product</h2>
          <p className='p'>
          Watch a demo of the final product below or download the .apk for Android devices <a target="_blank" href="https://soundboard.carrd.co/" style={{color: this.accent_color}}>here</a>.
          </p>
          <iframe
            style={{border:'0', borderRadius:'10px'}}
            width="100%"
            height="480"
            src="https://www.youtube.com/embed/-Zply3ak25A"
            title="Soundboard Demo"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          {/* <iframe
            style={{border:'0', borderRadius:'10px'}}
            src="https://drive.google.com/file/d/1D1IvO1R34PEhcX2XdxD1NFaeDyDTeke7/preview"
            width="100%"
            height="480"
            allow="autoplay">
          </iframe> */}

          <br/><br/>

          <h2 className='h2'>Challenges & Takeaways</h2>
          <p className='p'>
          In creating this project, we learned how to:
          </p>
          <ul className='ul'>
            <li className='li'>Create custom UI components </li>
            <li className='li'>Use Firebase Cloud & Database</li>
            <li className='li'>Implement user authentication</li>
            <li className='li'>Search and implement Tags</li>
            <li className='li'>Use Recyclerview</li>
            <li className='li'>Stream Audio & Image Media from Firebase</li>
            <li className='li'>Implement Outside Libraries</li>
            <li className='li'>Teamwork, Git, SE workflow</li>
          </ul>

          <p className='p'>
          Features we wanted to implement, but had technical difficulties/ran out of time:
          </p>
          <ul className='ul'>
            <li className='li'>Following other users </li>
            <li className='li'>Upload profile image</li>
            <li className='li'>Advanced filtering</li>
            <li className='li'>Setting the board image </li>
          </ul>

          <p className='p'>
          Other challenges:
          </p>
          <ul className='ul'>
            <li className='li'>Managing async calls</li>
            <li className='li'>Big Data Management</li>
            <li className='li'>Connecting Firebase Storage, Auth, and Realtime Database together</li>
            <li className='li'>Firebase daily storage quota limitation</li>
          </ul>

          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={sb8}></img>
          </div>

          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default SoundboardProjectPage;
