// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import celestial1 from '../../assets/celestial/celestial1.png';
import celestial2 from '../../assets/celestial/celestial2.JPG';
import celestial3 from '../../assets/celestial/celestial3.png';
import celestial4 from '../../assets/celestial/celestial4.png';
import celestial5 from '../../assets/celestial/celestial5.png';
import celestial6 from '../../assets/celestial/celestial6.png';
import celestial7 from '../../assets/celestial/celestial7.png';
import celestial8 from '../../assets/celestial/celestial8.png';
import celestial9 from '../../assets/celestial/celestial9.png';
import celestial10 from '../../assets/celestial/celestial10.png';
import celestial11 from '../../assets/celestial/celestial11.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class CelestialProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/celestial_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#5C7DD2'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark"/>
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}}/>
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Celestial Gate</h1>
          <h2 className="h2">
          <a target="_blank" href="https://docs.google.com/presentation/d/1d5X6eW3QcAgXtfq7prSuY2qJTUQ9C0P73c1I6cMuPyk/edit#slide=id.ga2e2ff124a_1_157" style={{color: this.accent_color}}> Celestial Gate </a>
          is a pavilion concept designed for the Hyde Park Winter Wonderland. Its form is inspired by the aurora beams and ice floes associated with the north.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["Physical", "3D"])}
          </div>

           {/* Background */}
           <h2 className='h2'>Overview</h2>
            <p className='p'>
            <i>Celestial Gate</i> is a pavilion concept designed for the Hyde Park Winter Wonderland. Its form is
            inspired by the aurora beams and ice floes associated with the north. </p>
            <p className='p'>
            The pavilion would be built primarily using panels of recycled plastic and stationed along the
            path that leads to the Albert Memorial. At the top of the pavilion is a plexiglass arrangement of
            the stars appearing above London on December 25th, 12:00am. Several light fixtures should be
            added along the bottom and top of the pavilion </p>


           {/* Graphical list idk */}
           <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["November 2020",
                         "Designer",
                         "Blender\nFusion 360\nRhino\nAdobe Illustrator\nAdobe Photoshop\n3D Printing",
                         "Mira Ram"]}
          />

          <h2 className='h2'>Poster</h2>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={celestial11}></img>
          </div>
          <br/>

            <h2 className='h2'>Concept sketch</h2>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={celestial1}></img>
          </div>
          <br/>

          <h2 className='h2'>3D print prototype</h2>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={celestial2}></img>
          </div>
          <br/>

          <h2 className='h2'>Hyde Park Representation</h2>
          <div style={{width:'100%', display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'row'}}>
            <img style={{minHeight:'10vh',maxHeight:'28vw', display:'inline-block', padding:'5px'}} src={celestial3}></img>
            <img style={{minHeight:'10vh',maxHeight:'28vw', display:'inline-block',padding:'5px'}} src={celestial4}></img>
          </div>
          <br/>

          <h2 className='h2'>Details</h2>
          <div style={{width:'100%', display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'row'}}>
            <img style={{minHeight:'10vh',maxHeight:'28vw', display:'inline-block', padding:'5px'}} src={celestial5}></img>
            <img style={{minHeight:'10vh',maxHeight:'28vw', display:'inline-block',padding:'5px'}} src={celestial6}></img>
          </div>
          <br/>

          <h2 className='h2'>Final Renders</h2>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={celestial7}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={celestial8}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={celestial9}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={celestial10}></img>
          </div>
          <br/>

          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default CelestialProjectPage;
