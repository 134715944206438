import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ParallaxProvider } from 'react-scroll-parallax';
import LandingPage from './Pages/LandingPage/LandingPage'
import UnmaskedProjectPage from './Pages/ProjectDetailPage/UnmaskedProjectPage';
import AboutMe from './Pages/AboutPage/AboutMe';
import ProjectDataPage from './Pages/WorksPage/ProjectDataPage';
import FlourishProjectPage from './Pages/ProjectDetailPage/FlourishProjectPage';
import ProducePayProjectPage from './Pages/ProjectDetailPage/ProducePayProjectPage';
import SoundboardProjectPage from './Pages/ProjectDetailPage/SoundboardProjectPage';
import HobbyIllustProjectPage from './Pages/ProjectDetailPage/HobbyIllustProjectPage';
import AppIllustProjectPage from './Pages/ProjectDetailPage/AppIllustProjectPage';
import TrusstProjectPage from './Pages/ProjectDetailPage/TrusstProjectPage';
import UnleashedProjectPage from './Pages/ProjectDetailPage/UnleashedProjectPage';
import FourSeasonsProjectPage from './Pages/ProjectDetailPage/FourSeasonsProjectPage';
import CBWProjectPage from './Pages/ProjectDetailPage/CBWProjectPage';
import DarthubProjectPage from './Pages/ProjectDetailPage/DarthubProjectPage';
import DALIProjectPage from './Pages/ProjectDetailPage/DALIProjectPage';
import PerfumeProjectPage from './Pages/ProjectDetailPage/PerfumeProjectPage';
import SnapchatProjectPage from './Pages/ProjectDetailPage/SnapchatProjectPage';
import LanguageProjectPage from './Pages/ProjectDetailPage/LanguageProjectPage';
import OceanProjectPage from './Pages/ProjectDetailPage/OceanProjectPage';
import CelestialProjectPage from './Pages/ProjectDetailPage/CelestialProjectPage';
import SilvrProjectPage from './Pages/ProjectDetailPage/SilvrProjectPage';
import TheatreProjectPage from './Pages/ProjectDetailPage/TheatreProjectPage';
import PrintProjectPage from './Pages/ProjectDetailPage/PrintProjectPage';
import CircleProjectPage from './Pages/ProjectDetailPage/CircleProjectPage';
import FireflyProjectPage from './Pages/ProjectDetailPage/FireflyProjectPage';
import MeshProjectPage from './Pages/ProjectDetailPage/MeshProjectPage';
import EsaiProjectPage from './Pages/ProjectDetailPage/EsaiProjectPage';
import ComicsProjectPage from './Pages/ProjectDetailPage/ComicsProjectPage';
import GLSLProjectPage from './Pages/ProjectDetailPage/GLSLProjectPage';
import PositionProjectPage from './Pages/ProjectDetailPage/PositionProjectPage';
import CampfireProjectPage from './Pages/ProjectDetailPage/CampfireProjectPage';
import ByWaterProjectPage from './Pages/ProjectDetailPage/ByWaterProjectPage';
import LandingPage2 from './Pages/LandingPage2/LandingPage2';

import './App.css';
import TranslateButton from './Components/TranslateButton/TranslateButton';
import ArrowRight from './assets/icons/arrow-right.svg';
import ScreeningButton from './Components/ScreeningButton/ScreeningButton';

class App extends React.Component {
  render() {
    return (
      <div className="app-container">
        <div className="centered-container">
          <Router>
            <Switch>
              <Route path="/about">
                <AboutMe/>
              </Route>
              <Route path="/about-me">
                <AboutMe/>
              </Route>
              <Route path="/resume" component={() => {
                    window.location.href = 'https://www.dropbox.com/scl/fi/z0vjx90onom76wkt1jmz3/mira_resume04262023.pdf?rlkey=wbpygcufyv5w09mnfrmwy4325&dl=0';
                    return null;}}
              />
              <Route path="/work">
                <ProjectDataPage/>
              </Route>

              {/* Route to individual project pages */}
              <Route path="/unmasked">
                <UnmaskedProjectPage/>
              </Route>

              <Route path="/flourish">
                <FlourishProjectPage/>
              </Route>

              <Route path="/producepay">
                <ProducePayProjectPage/>
              </Route>

              <Route path="/soundboard">
                <SoundboardProjectPage/>
              </Route>

              <Route path="/hobbyillust">
                <HobbyIllustProjectPage/>
              </Route>

              <Route path="/appillust">
                <AppIllustProjectPage/>
              </Route>

              <Route path="/trusst">
                <TrusstProjectPage/>
              </Route>

              <Route path="/unleashed">
                <UnleashedProjectPage/>
              </Route>

              <Route path="/fourseasons">
                <FourSeasonsProjectPage/>
              </Route>

              <Route path="/cbw">
                <CBWProjectPage/>
              </Route>

              <Route path="/darthub">
                <DarthubProjectPage/>
              </Route>

              <Route path="/dali3d">
                <DALIProjectPage/>
              </Route>

              <Route path="/perfume">
                <PerfumeProjectPage/>
              </Route>

              <Route path="/snapchat">
                <SnapchatProjectPage/>
              </Route>

              <Route path="/language">
                <LanguageProjectPage/>
              </Route>

              <Route path="/ocean">
                <OceanProjectPage/>
              </Route>

              <Route path="/celestial">
                <CelestialProjectPage/>
              </Route>

              <Route path="/silvr">
                <SilvrProjectPage/>
              </Route>

              <Route path="/theatre">
                <TheatreProjectPage/>
              </Route>

              <Route path="/print">
                <PrintProjectPage/>
              </Route>

              <Route path="/circle">
                <CircleProjectPage/>
              </Route>

              <Route path="/firefly">
                <FireflyProjectPage/>
              </Route>

              <Route path="/mesh">
                <MeshProjectPage/>
              </Route>

              <Route path="/esai">
                <EsaiProjectPage/>
              </Route>

              <Route path="/comics">
                <ComicsProjectPage/>
              </Route>

              <Route path="/glsl">
                <GLSLProjectPage/>
              </Route>

              <Route path="/position">
                <PositionProjectPage/>
              </Route>

              <Route path="/campfire">
                <CampfireProjectPage/>
              </Route>

              <Route path="/bywater">
                <ByWaterProjectPage/>
              </Route>

              <Route path="/test_home">
                <LandingPage />
              </Route>

              <Route path="/testing_button">
                <TranslateButton href='/work'
                    left_icon={ArrowRight}
                    middle_text="&nbsp;&nbsp; Check out my work &nbsp;&nbsp;"
                    right_icon={ArrowRight}
                    color="dark"
                />
              </Route>

              <Route path="/testing_screening_button">
                <ScreeningButton href='/work' color="pink"
                  onClick={()=>{}}>
                  Check out my work
                </ScreeningButton>
                <ScreeningButton href='/work' color="pink"
                  onClick={()=>{}}
                  text="text test">
                </ScreeningButton>
              </Route>

              {/* default route */}
              <Route path="/">
                <LandingPage2 />
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
    )
  }
}

export default App;