import React, {useState} from 'react';
import {FaArrowDown, FaArrowUp} from 'react-icons/fa';
import './ScrollDownButton.scss';
import circleDown from '../../assets/landing_page_2/circle-down.svg';

const ScrollDownButton = () =>{
    const scrollDown = () => {
        document.getElementById('scroll-target')?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="scroll-down" onClick={scrollDown}>
            <img className="scroll-down-button" src={circleDown}/>
        </div>

    );
}

export default ScrollDownButton;