// libraries
import React from 'react';
import PropTypes from 'prop-types';
import { BsFillCaretDownFill } from 'react-icons/bs'
// assets
import miraIllustration from '../../assets/mira_illust.png';
// css
import './LandingPage.css';
import './HelloCard.css';
import './HelloCardMobile.css';
import HeaderBar from '../../Components/HeaderBar';

class HelloCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imageIsReady: false
    }
  }

  componentDidMount() {
    this.props.onRender()
    const img = new Image();
    img.onload = () => {
      // when it finishes loading, update the component state
      this.setState({ imageIsReady: true });
    }
    img.src = miraIllustration; // by setting an src, you trigger browser download
  }

  render() {
    if (this.props.isMobile) return this.mobileRender()
    else return this.normalRender();
  }

  mobileRender() {
    return (
      <div className='hello-page-total-mobile-wrapper'>
        <HeaderBar/>
        <div className="hello-page-card-mobile-wrapper">
          <div>
            <img className='intro-img-mobile' src={miraIllustration}/>
          </div>
          <div className="intro-blurb-mobile">
            <h1 style={{textTransform:"lowercase", marginTop:"10px"}}>Hi, I'm Mira</h1>
            <p style={{textTransform:"lowercase", marginTop:"10px"}}>I design elegant & impactful solutions using my background in <b>computer science</b>, <b>digital arts</b>, and <b>psychology</b>
            </p>
            <a className="intro-button"
              style={{cursor:'pointer'}}
              //  this is for autoscrolling but for now we are using this button to href instead
              //  onClick={this.props.onClick}
              href='/work'
              >
              <div style={{color: 'white'}}>check out my work</div>
            </a>
          </div>
          <div className="intro-scroll-down">
            <a style={{textAlign:'center', cursor:'pointer'}} onClick={this.props.onClick}>
              <p className='no-decoration' style={{marginBottom: 0}}>or <b>scroll</b> for some <b>featured projects</b>&emsp;<BsFillCaretDownFill /></p>
            </a>
            <div style={{height: '10vh'}}/>
          </div>
        </div>
      </div>
    )
  }

  normalRender() {
    const { imageIsReady } = this.state;
    return (
      <div id={this.props.cssId} className={'landing-page-card-wrapper'}>
        <div className='hello-page-card-flex-wrapper'>
          <HeaderBar/>
          { imageIsReady ?
            <div className='hello-page-blurb-flex-wrapper'>
              <div className="left-side">
                <div/>
                <div className="intro-blurb">
                <h1 style={{textTransform:"lowercase", marginTop:"10px"}}>Hi, I'm Mira</h1>
                  <p style={{textTransform:"lowercase", marginTop:"10px"}}>I design elegant & impactful solutions using my background in <b>computer science</b>, <b>digital arts</b>, and <b>psychology</b>
                  </p>
                  <a className="intro-button"
                    style={{cursor:'pointer'}}
                    //  this is for autoscrolling but for now we are using this button to href instead
                    //  onClick={this.props.onClick}
                    href='/work'>
                      <div className="intro-button">check out my work</div>
                  </a>
                </div>
                <div className="intro-scroll-down">
                  <a onClick={this.props.onClick} style={{cursor:'pointer'}}>
                    <p className='no-decoration'>or <b>scroll</b> for some <b>featured projects</b>&emsp;<BsFillCaretDownFill /></p>
                  </a>
                </div>
              </div>
              <div className='intro-img'>
                <img style={{width: '50vw'}} src={miraIllustration}/>
              </div>
            </div> : <div/>
          }
        </div>
        {this.props.imageDecos}
      </div>
    )
  }
}

HelloCard.propTypes = {
  // array of absolutely positioned images inside the card (useful for background decoration and such)
  imageDecos: PropTypes.array,

  // big image to complement the text
  image: PropTypes.element,
  // css id
  cssId: PropTypes.string,

  // call this when rendered (see parent for performance implication)
  onRender: PropTypes.func
}

export default HelloCard;
