// libraries
import React from 'react';
import PropTypes from 'prop-types';
import { checkMobile } from "../../../Styles/ScreenCalcs";

// css
import './ProjectDetailPage.css';

class ProjectDetailListComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  generateColumns() {
    let columns = this.props.titles.map((t, index) => {
      let title = this.props.titles[index];
      let text = this.props.textFields[index];
      return (
        <div className='project-detail-info-box-column'>
          <h3 className='font-large'>{title}</h3>
          <br/>
          {text.split('\n').map((item) => {
            return (
              <p className='font-medium' style={{marginBottom:'0px'}}>{item}</p>
            )
          })}
        </div>
      )
    });
    return columns;
  }

  render() {
    let mobile = ''
    if(checkMobile()) {
      mobile = '-mobile'
    }
    return (
      <div className={`project-detail-info-box-color project-detail-page-full-width-div${mobile}`}  style={{backgroundColor: this.props.color + "19"}}>
        <div className='project-detail-info-box'>
            {this.generateColumns()}
        </div>
      </div>
    )
  }
}

ProjectDetailListComponent.propTypes = {
    titles: PropTypes.array,
    textFields: PropTypes.array
}

export default ProjectDetailListComponent;
