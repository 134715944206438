// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import secondary_research from '../../assets/flourish/secondary_research.png';
import primary_research from '../../assets/flourish/primary_research.png';
import specifications from '../../assets/flourish/specifications.png';
import final_product from '../../assets/flourish/flourish_monitor_and_phone.png';

import darthub1 from '../../assets/darthub/darthub1.png';
import darthub2 from '../../assets/darthub/darthub2.png';
import darthub3 from '../../assets/darthub/darthub3.png';
import darthub4 from '../../assets/darthub/darthub4.png';
import darthub5 from '../../assets/darthub/darthub5.png';
import darthub6 from '../../assets/darthub/darthub6.png';
import darthub7 from '../../assets/darthub/darthub7.png';
import darthub8 from '../../assets/darthub/darthub8.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class DarthubProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/darthub_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#7BB56F'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Dartmouth: Darthub</h1>
          <h2 className="h2">
          <a target="_blank" href="https://services.dartmouth.edu/TDClient/2415/Student/KB/ArticleDet?ID=107237" style={{color: this.accent_color}}>Darthub </a>
          is Dartmouth's current student portal. I worked on creating new interfaces for a chosen name
          and gender project as well as for a freshman onboarding wizard. Both features are
          <a target="_blank" href="https://www.thedartmouth.com/article/2019/09/students-allowed-to-select-names-pronouns-and-identities-online" style={{color: this.accent_color}}> now integrated </a>
          into Darthub's interface.



          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["UI/UX"])}
          </div>

          {/* 2022 Notes */}
          <h2 className='h2'>2022 Notes</h2>
          <p className='p'>
          This case study was originally written in 2019. While this is one of my earlier design projects,
          I found this project incredibly rewarding as a result of its direct effect on Dartmouth's student
          body. This was also the first time the college had made an attempt to apply the design
          process to any of their essential tools. I was also
          <a target="_blank" href="https://www.thedartmouth.com/article/2019/09/students-allowed-to-select-names-pronouns-and-identities-online" style={{color: this.accent_color}}> featured in The Dartmouth </a>

          for my work on this project.    </p>

          <p className='p'>
          Now, the 2019 case study:
          </p>

            {/* Graphical list idk */}
          <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["September – November 2018\n(3 mo)",
                         "Designer",
                         "User Research\nWireframing\nPrototyping\nDesign System\nFigma",
                         "Mira Ram\nKaitlyn Hahn"]}
          />

          {/* Background */}
          <h2 className='h2'>Background</h2>
          <p className='p'>
          DartHub is the name of Dartmouth’s current student portal for everything from checking grades to
          viewing your housing assignment. As such an invaluable service to students, the school has finally
          decided to make a step towards making the site more user-friendly, and recently revamped a large part of
          the user interface. However, a lot of work still needs to be done on the site, and for this reason, the
          Dartmouth ITC (information, technology, and consulting) staff requested a DALI team to design a new
          part of DartHub that would consolidate all personal information pages into one overarching profile
          page and additionally include new features that would allow students to input their chosen name,
          gender, and personal pronouns. Our ITC partners requested both a desktop and mobile site for this
          new interface.
          </p>
          <br/>


          {/* Initial User Research */}
          <h2 className='h2'>Sketches, greyscales, and user research</h2>
          <p className='p'>
          I could not have been more thrilled about the opportunity to work on a service that is so vital to the
          student body. I eagerly dived into user research and asked my fellow students (who were equally eager to
          respond) about their thoughts on the current student portal. The vast majority of respondents stated that
          while they appreciated the effort that had gone into to revamping DartHub, they still found the interface
          convoluted and difficult to use, to the point that many of them were unaware of some useful
          organizational features added to DartHub until I informed them of those features’ existence. There was
          also a resonating comment that the current left sidebar was fairly useless, and could be removed.
          Finally, since the ITC partners were primarily focused on the development of new profile page, I did ask
          students about whether they felt such a page was necessary, and what they thought might be included on
          such a page. A few students felt this page was not necessary, but overall, all students generally had
          the same idea of what a ‘profile’ page might look like.
          </p>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'60vw', display:'inline-block'}} src={darthub1}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <p className='p' style={{fontSize:"10px"}}>Initial concept sketches and user flow diagrams</p>
          </div>
          <br/>
          <p className='p'>
          I relayed this information to the ITC partners, and they were surprisingly flexible about our design
          decisions for the profile app. We initially were told to stick to the general DartHub style guide and
          keep the top and left side bars, but they were open to letting us change the design to fit the wants
          of the students. With this in mind, I created my first greyscale mockups. I started off sticking to
          the requirements they had given us and worked from there, adding a page navigation under the top bar.
          Since there would be some fields that would not be editable (such as legal name and date of birth),
          I decided that while editing fields, those static ones would be greyed out. In regards to editing,
          I tried adding both a pencil icon next to editable fields and just having an edit button at the bottom
          of the page.
          </p>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'60vw', display:'inline-block'}} src={darthub2}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <p className='p' style={{fontSize:"10px"}}>original greyscale mockups</p>
          </div>
          <br/>
          <p className='p'>
          After discussing my original designs with my DALI teammates, and considering the freedom the ITC
          staff had given up, we produced a new, revised set of greyscale mockups. We decided to use an edit
          button over the pencils, as the latter could start to look clunky with too many fields, and
          it would be difficult to edit many fields at once. We also decided to remove the original help
          left sidebar and replace it with a new profile navigation, eliminating the need for a horizontal
          navigation bar. We also learned that there were many different categories of contact information
          a user would have to input, so we created a menu exclusive to the contact page for organizing this
          information.
          </p>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'60vw', display:'inline-block'}} src={darthub3}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <p className='p' style={{fontSize:"10px"}}>revised greyscale mockup for contact page</p>
          </div>
          <br/>
          <p className='p'>
          However, we still needed to design a mobile interface for the application, as the ITC staff later
          indicated that it was of a greater priority than the web version. With the sheer amount of information
          the profile page would hold, we made our best attempt at designing a mobile interface. The most difficult aspect of the profile to translate into a mobile-sized screen was the contact page and its exclusive menu. We tried a bottom menu and an accordion style layout, but still weren’t quite happy with the results. The ITC partners were not too keen on these ideas either
          </p>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'60vw', display:'inline-block'}} src={darthub4}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <p className='p' style={{fontSize:"10px"}}>mobile greyscale mockups</p>
          </div>
          <br/>


          {/* Iteration */}
          <h2 className='h2'>Iterations</h2>
          <p className='p'>
          I continued to expand on our initial greyscales and began our color mockups. We stuck to the DartHub
          style guide in terms of color and opted for darked accents on the left sidebar and contact navigation
          bar. We experimented with different borders around the fields that would change in hover and clicked
          states. Additionally, we received feedback that edit button seemed hidden, and it might be more
          beneficial to have a permanently editable page, with un-editable fields not having a box around them.
          </p>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'60vw', display:'inline-block'}} src={darthub5}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <p className='p' style={{fontSize:"10px"}}>Initial color mockups</p>
          </div>
          <br/>
          <p className='p'>
          Returning to the issue of the mobile contact menu, we settled on a dropdown style of navigation and
          experimented with different color layouts. In our initial color mobile mockups, we thought to keep
          the entirety of “DartHub student” in the top bar and furthermore keep the save button at the bottom
          right, continuing on the permanently editable state. These features would come to change in discussing
          them with the rest of the DALI team. While our ITC partners had little issue with the permanently
          editable state, the developers on the project didn’t seem to keen on the feature. As a compromise,
          we would opt to include a cancel button next to the save button, in case users wanted to reset their
          changes without leaving the page.

          </p>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'60vw', display:'inline-block'}} src={darthub6}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <p className='p' style={{fontSize:"10px"}}>Initial color mockups (featuring contact page)</p>
          </div>
          <br/>

          {/* Final Product */}
          <h2 className='h2'>Final Product</h2>
          <p className='p'>
          With the final product, the desktop view included additional checkboxes under the chosen name field
          to allow students to decide whether or not they would like their chosen name to appear on their student
          ID and transcript. As mentioned previously, a cancel button was added, and the button placement was
          decided on the bottom right side of the page. Since we were previously using icons from the noun
          project, we decided to make entirely new icons for the navigation bar (with the exception of the
          religious icon, which is already a Dartmouth icon).
          </p>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'60vw', display:'inline-block'}} src={darthub7}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <p className='p' style={{fontSize:"10px"}}>High-fidelity mockups of the final desktop screens</p>
          </div>
          <br/>
          <p className='p'>
          As for the mobile page, we made changes to the navigation menu after receiving feedback from the ITC
          staff. The icons were removed and the text was aligned left for easier reading and a cleaner appearance.
          Furthermore, a box with shadow was added under the cancel and save buttons, since the two buttons would
          always stick to the bottom of the screen. Finally, “student” was removed from the top bar so it would
          not take up as much space on the screen.
          </p>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'60vw', display:'inline-block'}} src={darthub8}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <p className='p' style={{fontSize:"10px"}}>High-fidelity mockups of the final mobile screens</p>
          </div>
          <br/>

          {/* Moving Forward */}
          <h2 className='h2'>Moving Forward</h2>
          <p className='p'>
          This project took place from September to November of 2018. After my DALI team presented our final
          product to the entire ITC staff, they expressed their delight with our work and have since requested our
          team for another term. Our next project will revolve more around first-year students and the
          prematriculation process. In all honesty, I had been worried about sticking to the DartHub style guide,
          which I was not a fan of myself. However, thanks to the flexibility of the project partners, I feel I was
          able to expand on and ameliorate the current DartHub design and take it in a new direction. With a new chapter
          of this project on the horizon, I look forward to working more on DartHub and making a difference at my school.
          View the demo videos for the web and mobile products below.
          </p>
          <br/>
          <iframe
            width="100%"
            height="519"
            src="https://www.youtube.com/embed/JY41uD7ieuY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
          <br/> <br/><br/>

          <iframe
            width="100%"
            height="519"
            src="https://www.youtube.com/embed/npEsGWkgGQI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>



          <br/><br/>


        </div>
        <Footer/>
      </div>
    )
  }
}

export default DarthubProjectPage;
