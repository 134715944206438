// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import print1 from '../../assets/print/print1.png';
import print2 from '../../assets/print/print2.png';
import print3 from '../../assets/print/print3.png';
import print4 from '../../assets/print/print4.png';
import print5 from '../../assets/print/print5.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class PrintProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/print_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#FFA07A'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark"/>
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}}/>
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Print</h1>
          <h2 className="h2">
          An assortment of print layouts I've created for various projects.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["Illustration", "UI/UX"])}
          </div>


          {/* Background */}
          <h2 className="h2"> CBW Bank </h2>
          <p className='p'><a target="_blank" href="https://cbwpayments.com/" style={{color: this.accent_color}}> CBW Bank </a>
          is a leader in payment and lending solutions, providing technology for increased efficieny. Below is
          a sample of the designs I created for an executive summary. Details have been hidden to maintain confidentiality.
          <a href="/cbw" style={{color: this.accent_color}}> Read the full case study here</a>.
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={print1}></img>
          </div>

          <h2 className="h2"> Flourish </h2>
          <p className="p"><a target="_blank" href="https://www.flourish.care/" style={{color: this.accent_color}}> Flourish</a> is an app designed to help patients with multi-symptom chronic illnesses better track,
          visualize, and communicate their symptoms and triggers over time. Below is a sample of the designs I created for the project report. <a href="/flourish" style={{color: this.accent_color}}>Read the full case study here</a>.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={print2}></img>
          </div>

          <h2 className="h2"> MEDLIFE </h2>
          <p className="p"><a target="_blank" href="https://www.medlifemovement.org/" style={{color: this.accent_color}}> MEDLIFE </a>
          is an organization that partners with low-income communities in Latin America and Africa to improve their access to
          medicine, education, and community development initiatives. MEDLIFE has local university chapters that utilise
          "playbooks" to explain critical goals and guidelines to students in these chapters. Below is a sample of the
          designs I created for their playbooks.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={print3}></img>
          </div>

          <h2 className="h2"> Inter[twin]ed </h2>
          <p className="p"><a target="_blank" href="https://intertwined-i7.carrd.co/#" style={{color: this.accent_color}}> Inter[twin]ed </a>
          is a charity fanzine I helped organize and design. Below is a sample of the
          designs I created for written works. Details have been hidden to maintain confidentiality.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={print4}></img>
          </div>

          <h2 className="h2"> The edge in NYC </h2>
          <p className="p"> A two page spread created as a part of Dartmouth's Digital Design course.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={print5}></img>
          </div>



          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default PrintProjectPage;
