import React from "react";
import { isMobile, isSafari } from 'react-device-detect'

import './LandingPageProjectCard.scss';
import ArrowRight from "../../assets/icons/arrow-right.svg";
import TranslateButton from "../../Components/TranslateButton/TranslateButton";

import "../../Styles/Fonts.scss";
import { checkMobile } from "../../Styles/ScreenCalcs";

import 'intersection-observer'; // optional polyfill
import Observer from '@researchgate/react-intersection-observer';

export default class LandingPageProjectCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: checkMobile(),
            isVisible: false,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateLayout);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateLayout);
    }

    updateLayout = () => {
        this.setState({ isMobile: checkMobile() });
    }

    getPrintableThreshold = (ratio, range) =>
        range.reduce((prev, curr) =>
          Math.abs(curr - ratio) < Math.abs(prev - ratio) ? curr : prev);

    handleIntersection = (event) => {
        if(!this.state.isVisible && event.intersectionRatio > 0.5) {
            this.setState({ isVisible: event.isIntersecting && event.intersectionRatio > 0.5 });
        }
    }

    animateIfVisible = () => {
        if (this.state.isVisible) {
            return " lppc-visible ";
        }
        return "";
    }

    render() {
        const options = {
            onChange: this.handleIntersection,
            threshold: [0, 0.25, 0.5, 0.75, 1]
        };
        return (
            <Observer {...options}>
                <div className={"landing-page-project-card " + this.animateIfVisible()}>
                    <img src={this.props.img} className="landing-page-project-card-image" />
                    <div className="landing-page-project-card-text">
                        <div className="title font-medium-large"><b>{this.props.title}</b> - {this.props.subtitle}</div>

                        <div className="blurb font-medium">{this.props.blurb}</div>
                    </div>
                    <div className="read-more-button font-small">
                        <TranslateButton href={this.props.page}
                            left_icon={ArrowRight}
                            middle_text="Read more"
                            right_icon={ArrowRight}
                            color="transparent"
                            size='small'
                            />
                    </div>
                </div>
            </Observer>
        );
    }
}