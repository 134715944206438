export const projectDetailData =
{
    bg: "",
    title: "Unmasked",
    subtitle: "Unmasked is an anonymous social media app for college students focused on mental health. The app is available on the iPhone and Android app stores.",
    tags: ["UI/UX", "graphic/illustration"],
    background: `While a significant number of college students struggle with mental illness, college campuses
    often fail to provide sufficient resources for students seeking help. Students at Dartmouth identified this
     issue at our school, and proposed a student-led alternative to the existing (but lacking) mental health resources
     where students could provide emotional support for each other in a safe, anonymous space, free from stigma.

        I joined the Unmasked team as a designer in 2018, when Unmasked was still early in its development.
        I now serve as the Head of Product. In January 2020, Unmasked officially launched for Dartmouth students.
        Once our user base began to grow, several pain-points with the existing designs became apparent.
        The app suffered from usability issues, and the UI was in need of an upgrade in aesthetics.
        For these reasons, I decided to spearhead a full redesign of the app.
        While design work for the app is still ongoing, this case study will focus on the most
        significant portion of the redesign which occurred from June – August 2020.`,
    info: {
        "Time frame": "June – August 2020 (3 mo)",
        "Role": "Head of Product",
        "Skills": ` Rapid Iteration
                    Wireframing
                    Prototyping
                    Usability Testing
                    User Interviewing
                    Figma`,
        "Team": `Mira Ram
                Callie Page (past)
                Yoko Urano
                Wylie Kasai
                Dylan Lew (past)
                Sanat Mohapatra`
    },
    the_old_unmasked: `Unmasked was designed around the following key functionalities for engaging with the student community, defined after initially interviewing Dartmouth students:

    Creating posts, and being able to sort and filter the posts of others
    Conversing with other users through comments on posts and direct messages (DMs)
    Supporting other users by “liking” their posts with a count only visible to the original poster
    Implementing a moderation system to maintain a safe community
    Easy access to relevant mental health resources
    The app itself should be inviting, friendly, and delightful to use

    With these features in mind, the previous designs were created over many iterations. Below is the set of designs that were ultimately implemented in the January 2020 release:`
}