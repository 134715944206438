// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import designs from '../../assets/soundboard/soundboard_mocks.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class FourSeasonsProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/fourseasons_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#D473D1'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Four Seasons AR</h1>
          <h2 className="h2">
          <a style={{color: this.accent_color}}> Four Seasons </a>
          lets you experience the scenic world of the painting <i>Between Walden and Hameau No. 4</i> with augmented reality.
          It was created as a part of a 1-week project for Dartmouth's VR/AR Design & Development course.

          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["XR", "Development", "3D", "Motion"])}
          </div>

           {/* Graphical list idk */}
           <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["October 2019 \n(1 week)",
                         "Designer",
                         "Unity\nMaya\nPhotoshop\nFigma\nVuforia\nC#",
                         "Mira Ram\nJames Lee\nAngi Li"]}
          />

          {/* Background */}
          <h2 className='h2'>About Four Seasons</h2>
          <p className='p'>
          Four Seasons lets you experience the scenic world of the painting <i>Between Walden and Hameau No. 4</i> with augmented reality.
          It was created as a part of a 1-week project for Dartmouth's VR/AR Design & Development course. <i>Between Walden and Hameau No. 4</i>,
          a 2002 oil painting by Delia Brown, was gifted to Dartmouth's Hood Museum and was on display during the fall of 2019. The Four Seasons
          AR app allows viewers of the painting to experience what this scenery might look like over the course of a year. Each season features its own special
          effects: snow in the winter, falling leaves in fall, flower petals in the spring. I designed all of the visual elements for this project.
          View a video of the experience below.

          </p>

          <iframe
            width="100%"
            height="480"
            src="https://www.youtube.com/embed/FtOA2NJqRE4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default FourSeasonsProjectPage;
