import React from "react";

import '../../Styles/Fonts.scss';
import "./ScreeningButton.scss";
import FirstHoverDetectComponent from "../SharedLib/FirstHoverDetectComponent";

export default class ScreeningButton extends FirstHoverDetectComponent {
  constructor(props) {
    super(props);
  }

  color = () => {
    return " " + this.props.color + " "
  }

  active = () => {
    return " " + (this.props.active ? "sb-active" : "") + " "
  }

  render() {
    return (
        <div
            onMouseEnter={this.addTransitionStyle}
            onClick={() => this.props.onClick()}
            className={"screening-button " + this.color() + " " + this.animOnStyle()}
          >
          <div className={"bg"+ this.active()} />
          {this.props.children}
          <div className="text">{this.props.text}</div>
        </div>
    )
  }
}