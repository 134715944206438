// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import designs from '../../assets/soundboard/soundboard_mocks.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class PositionProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/position_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#32B55A'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark"/>
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}}/>
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Position-Based Fluid</h1>
          <h2 className="h2">
          For this project, we implemented a
          <a target="_blank" href="http://mmacklin.com/pbf_sig_preprint.pdf" style={{color: this.accent_color}}> 2007 Position Based Fluids </a>
          paper by
          Miles Macklin and Matthias Muller, keeping in mind a
          <a target="_blank" href="https://matthias-research.github.io/pages/publications/posBasedDyn.pdf" style={{color: this.accent_color}}> 2006 Position Based </a>
          Dynamics
          paper by Matthias et.al.

          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["Development", "Motion"])}
          </div>

          {/* Background */}
          <h2 className='h2'>Overview</h2>
          <p className='p'>
          For this project, we implemented a
          <a target="_blank" href="http://mmacklin.com/pbf_sig_preprint.pdf" style={{color: this.accent_color}}> 2007 Position Based Fluids </a>
          paper by
          Miles Macklin and Matthias Muller, keeping in mind a
          <a target="_blank" href="https://matthias-research.github.io/pages/publications/posBasedDyn.pdf" style={{color: this.accent_color}}> 2006 Position Based </a>
          Dynamics
          paper by Matthias et.al.
          While we often deal with force-based approaches for physical simulations, position-based dynamics
          (PBD) aims to utilize a position-based approach for direct control over the positions of objects
          or vertices. Although the original paper applied PBD to cloth, the Position Based Fluids paper
          suggests an alternative to the SPH solver that incorporates the benefits of the PBD solver
          (speed, stability, controllability) while maintaining the look and feel of the SPH results.
          </p>

           {/* Graphical list idk */}
           <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["November 2020 \n(1 week)",
                         "Developer",
                         "C++\nPhysics simulation",
                         "Mira Ram\nQiyao Zuo"]}
          />

          <h2 className='h2'>Implementation Details</h2>
          <p className='p'>
          We implemented a 2D visualization of the PBD fluid solver outlined in the
          paper on top of the framework of the particle fluid assignment from class.
          Our implementation follows the structure
          <a target="_blank" href="https://docs.google.com/document/d/1RMVeo7QpcjHzw5N6k8hA_Tcq8zeoUrz0lIF1HBQ8lO8/edit" style={{color: this.accent_color}}> here</a>.
          </p>
          <p className='p'>
          Watch a video of the result below:
          </p>

          <iframe
            width="100%"
            height="480"
            src="https://www.youtube.com/embed/6P8kAMiWmvw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>


          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default PositionProjectPage;
