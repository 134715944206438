// libraries
import React from 'react';
import PropTypes from 'prop-types';

// css
import './ProjectDetailPage.css';
import './ScreenWithCaption.css';

class ScreenWithCaption extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const anyText = (this.props.text);
    return (
      <div className='caption-container'>
        <img src={this.props.image} className='image-box'/>
        { anyText ?
          <div className='caption-box' style={{height: this.props.height}}>
            <p className='caption-title'>{this.props.title}</p>
            <ul>
              {this.props.text.split('\n').map((item) => {
                return (
                  <li className='caption-text'>
                    {item}
                  </li>
                )
              })}
            </ul>
          </div>
       : <div/> }
      </div>
    )
  }
}

class ScreenWithCaptionList extends React.Component {
  constructor(props) {
    super(props)
  }

  generateColumns() {
    let columns = this.props.screens.map((t, index) => {
      let image = this.props.screens[index];
      let text = null;
      let title = null;
      if(this.props.text && this.props.text.length > index){
        text = this.props.text[index];
      }
      if(this.props.titles && this.props.titles.length > index) {
        title = this.props.titles[index];
      }
      return (
        <ScreenWithCaption key={index} image={image} text={text} title={title}/>
      )
    });
    return columns;
  }

  render() {
    return (
      <div className='fade-container'>
        <div className='project-screen-box hide-scroll'>
            {this.generateColumns()}
        </div>
        <div className='fade-left'/>
        <div className='fade-right'/>
      </div>
    )
  }
}

ScreenWithCaptionList.propTypes = {
    titles: PropTypes.array,
    textFields: PropTypes.array
}

export default ScreenWithCaptionList;
