// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';
import Image from "react-image-enlarger";
import ScrollButton from '../../Components/ScrollButton';
// assets
import snap1 from '../../assets/snapchat/snap1.gif';
import snap2 from '../../assets/snapchat/snap2.gif';
import snap3 from '../../assets/snapchat/snap3.gif';
import snap4 from '../../assets/snapchat/snap4.gif';
import snap5 from '../../assets/snapchat/snap5.gif';
import snap6 from '../../assets/snapchat/snap6.gif';
import snap7 from '../../assets/snapchat/snap7.gif';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


function ZoomableImg({ src }) {
  const [zoomed, setZoomed] = React.useState(false);

  return (
    <div style={{ padding: "0.25vw", overflow:'hidden', maxHeight: '500px'}}>
      <Image
        style={{ width: "300px", height: "auto", overflow: 'hidden' }}
        zoomed={zoomed}
        src={src}
        onClick={() => setZoomed(true)}
        onRequestClose={() => setZoomed(false)}
      />
    </div>
  );
}


class SnapchatProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/snapchat_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#D473D1'
    this.drawings = [
      snap1,
      snap2,
      snap3,
      snap4,
      snap5,
      snap6,
      snap7
    ]
    this.drawings = this.drawings.reverse()
  }
  // <div className="gallery-container gallery-container-margin">


  //               <a target="_blank" href={drawing34}>
  //                 <img src={drawing34}/>
  //               </a>

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  getImageCols() {
    let numCols = Math.floor(Math.max(this.state.width / 450 + 1, 1));
    console.log(numCols)
    let cols = [...Array(numCols)].map(e => Array(0));
    for(let i = 0; i < cols.length; i++){
      for(let j = i; j < this.drawings.length; j += numCols){
        cols[i].push(this.drawings[j])
      }
    }
    return cols.map( (drawings) => (
      <div className='gallery-column'>
        {drawings.map(image => (
          <ZoomableImg key={image} src={image} />
        ))}
      </div>
    ));
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark"/>
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}}/>
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">The Dartmouth: Snapchat Animations</h1>
          <h2 className="h2">
          As the head designer for
          <a target="_blank" href="https://www.thedartmouth.com/" style={{color: this.accent_color}}> the Dartmouth</a>'s
          multimedia team, I designed and animated top snaps in Adobe After
          Effects for the newspaper's weekly Snapchat story. Below are a few samples (click to view full image)
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["Motion", "Graphic Design", "Illustration"])}
          </div>


          {/* Background */}
          <div className='gallery-container2'>
            {this.getImageCols()}
          </div>
          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default SnapchatProjectPage;
