// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import circle1 from '../../assets/circle/circle1.png';
import circle2 from '../../assets/circle/circle2.png';
import circle3 from '../../assets/circle/circle3.png';
import circle4 from '../../assets/circle/circle4.png';
import circle5 from '../../assets/circle/circle5.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class CircleProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/circle_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#B8151C'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark"/>
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}}/>
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Circle of Life</h1>
          <h2 className="h2">
          <a href="https://docs.google.com/presentation/d/1d5X6eW3QcAgXtfq7prSuY2qJTUQ9C0P73c1I6cMuPyk/edit#slide=id.ga2e2ff124a_1_157" style={{color: this.accent_color}}> Circle of Life </a>
          is a laser-cut piece llustrating the transition from life to death through a tiger’s stripes and bones.
          In the piece’s physical installation, a light source is used to project the shadows, and the remaining
          material cut from the negative space is hung above.

          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["Physical", "3D"])}
          </div>

           {/* Background */}
           <h2 className='h2'>Overview</h2>
            <p className='p'>
            <i>Circle of Life</i> was created as an introduction to the process of creating, peparing, and assembling a laser-cut project.
            The design was created in Adobe Illustrator and Figma before it was sent for laser cutting. </p>

           {/* Graphical list idk */}
           <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["September 2020",
                         "Designer",
                         "\nAdobe Illustrator\nFigma\nLaser Cutting\nVector Art",
                         "Mira Ram"]}
          />

            <h2 className='h2'>Concept sketches</h2>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={circle1}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={circle2}></img>
          </div>
          <br/>

          <h2 className='h2'>Installation</h2>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={circle3}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={circle4}></img>
          </div>
          <br/>

          <h2 className='h2'>3D render</h2>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={circle5}></img>
          </div>

          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default CircleProjectPage;
