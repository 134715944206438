// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import designs from '../../assets/soundboard/soundboard_mocks.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';
import PasswordComponent from '../../Components/PasswordComponent';


class SilvrProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/silvr_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#8B96E0'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Veracity Research</h1>
          <h2 className="h2">
          <a style={{color: this.accent_color}} target="_blank" href="https://link.springer.com/chapter/10.1007/978-3-031-62281-6_21" > Virtual Avatar Movement Veracity and Its Effect on Social Interaction in Virtual Reality </a>
          is a research project advised and funded by a gift from Meta Research. The research was presented at the 2024 SAI Computing Conference, and the paper was published in the Springer series <a target="_blank" style={{color: this.accent_color}} href="https://link.springer.com/chapter/10.1007/978-3-031-62281-6_21">"Lecture Notes in Networks and Systems"</a>.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["XR", "Development", "3D","UI/UX"])}
          </div>

          {/* Background */}
          <h2 className='h2'>Thesis Abstract</h2>
          <p className='p'>
          The market for Virtual Reality (VR) products is growing rapidly, and we expect an important use case will be social interaction. This work contributes to the understanding of how social interaction and connection are impacted by how closely a user's real - life physical motion is tied to the motion of their virtual character, an “avatar.” We observed social interaction between 16 pairs of participants (32 participants in total) controlling VR avatars over four different movement conditions in a within - subjects experimental setup: directly - captured (movement directly captured from VR headset and hand controllers), predictive (a mixture of directly captured movement and predicted synthetic movement), synthetic (movement entirely based on pre - generated animations), and a static control group. Two key metrics were analyzed to gauge social connection: expressivity and engagement, with a significant effect of avatar movement condition on expressivity measures. The findings of this study suggest that users feel that they are best able to express themselves and that the expressions of others are best communicated when VR avatars feature a mixture of directly - captured and predictive synthetic movement. We also use this experiment to present a methodology that could be used for exploring other similar questions in VR.
          </p>
       
          <p className='p'>
          <a style={{color: this.accent_color}} href="https://link.springer.com/chapter/10.1007/978-3-031-62281-6_21" > Read the full paper here. </a>
          </p>

                      {/* Graphical list idk */}
                      <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["June 2021 - June 2022 \n(1 yr)",
                         "Principal Investigator / Researcher / Program Manager",
                         "Research\n 3D Modeling\nPrototyping\nUser Testing\nResearch Trials\nData Analysis\nUnity\nMaya\nFigma\nPython\nJupyter Notebooks",
                         "Mira Ram (PI)\nProfessor James Mahoney\nProfessor Lorie Loeb\nProfessor Michael Cohen\nAmon Ferri (SILvr team)\nMacy Toppan (SILvr team)\nAnna Wu (SILvr team)\nMichelle Chen (SILvr team)\nKelly Wang (SILvr team)\nJacob Chen (SILvr team)\nAdam Glueck (SILvr team)\nBrett Kidman (SILvr team)"]}
          />

<h2 className='h2'>Lab Background</h2>
          <p className='p'>
          SILvr is a Dartmouth research project focused on deepening our understanding of what factors most influence the creation of social connection in a virtual environment. The SILvr platform is a persistent, online, multi-user VR environment to act as a testbed for experiments with
          social interaction technology in XR. We investigate ways in which environments, avatars, and
          interfaces can be used to promote a more natural and immersive social interaction and collaboration in
          virtual reality. The goal is to deepen our understanding of what factors most influence the
          creation of social connection in a virtual environment.
          The research project is supported by a gift from
          <a target="_blank" href="https://research.facebook.com/" style={{color: this.accent_color}}> Meta (Facebook). </a>

          <br/><br/>
          I served as the program manager for the SILvr project, overseeing the various different research
          initiatives within the lab. I additionally led my own thesis research on avatar movement veracity, advised by Professors <a target="_blank" href="https://web.cs.dartmouth.edu/people/james-mahoney" style={{color: this.accent_color}}>James Mahoney</a> (Senior Lecturer and Head of the SILvr Lab), <a target="_blank" href="https://web.cs.dartmouth.edu/people/lorie-loeb" style={{color: this.accent_color}}>Lorie Loeb</a> (Research Professor and Director of Digital Arts at Dartmouth), and <a target="_blank" href="https://research.facebook.com/people/cohen-michael/" style={{color: this.accent_color}}> Michael Cohen</a> (Director of Computational Photography at Meta)
          </p>

          {/* <PasswordComponent
        sha256={'fc97013f69b1cbc4d4b26220fb8903991343024fd159fc6013974eda2a09d2c5'}
        color={this.accent_color}> */}

          {/* <p className='p'>
          <ul className='ul'>
            <li className='li'><a target="_blank" href="https://www.dropbox.com/scl/fi/m65w9z8t02e6dilob48r7/thesis_paper_digital.pdf?rlkey=c4gdnotky5ov50d1z4cik8lho&dl=0" style={{color: this.accent_color}}>Full thesis paper</a></li>
            <li className='li'><a target="_blank" href="https://docs.google.com/presentation/d/14csJIY1i3EyCrUcMKAoefkrA5_DviNHzy2y4kDcWq8s/edit#slide=id.JCk7ePUW" style={{color: this.accent_color}}>Thesis defense presentation</a></li>
          </ul>

          </p> */}

          <a href="https://www.dropbox.com/scl/fi/m65w9z8t02e6dilob48r7/thesis_paper_digital.pdf?rlkey=c4gdnotky5ov50d1z4cik8lho&dl=0"></a>

          <p></p>
          {/* </PasswordComponent> */}
        </div>
        <Footer/>
      </div>
    )
  }
}

export default SilvrProjectPage;
