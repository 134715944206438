// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import ocean1 from '../../assets/ocean/ocean1.png';
import ocean2 from '../../assets/ocean/ocean2.png';
import ocean3 from '../../assets/ocean/ocean3.png';
import ocean4 from '../../assets/ocean/ocean4.png';
import ocean5 from '../../assets/ocean/ocean5.png';
import ocean6 from '../../assets/ocean/ocean6.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class OceanProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/ocean_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#2DA4E7'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Ocean Prism</h1>
          <h2 className="h2">
          <a target="_blank" href="https://docs.google.com/presentation/d/1d5X6eW3QcAgXtfq7prSuY2qJTUQ9C0P73c1I6cMuPyk/edit#slide=id.ga2e2ff124a_1_157" style={{color: this.accent_color}}> Ocean Prism </a>
          is a concept for a physical art installation featuring suspended pyramids in space. This was created
          as a part of Dartmouth's Digital Fabrication course.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["Physical", "3D"])}
          </div>

           {/* Background */}
           <h2 className='h2'>Overview</h2>
            <p className='p'>
            <i>Ocean Prism</i> was created as a part of an assignment to design a unique box. The
            project evolved from a jellyfish box to a floating installation piece. Read more about
            my process and iterations below. </p>

           {/* Graphical list idk */}
           <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["October 2020",
                         "Designer",
                         "Blender\nFusion 360\nAdobe Illustrator\nAdobe Photoshop\n3D Printing\nLaser Cutting",
                         "Mira Ram"]}
          />

            <h2 className='h2'>Concept sketches & jellyfish box</h2>
            <p className='p'>
            Below are my intial concept sketches and the laser-cut prototype for a jellyfish-themed box. </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={ocean1}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={ocean2}></img>
          </div>
          <br/>

          <h2 className='h2'>First ocean 3D print</h2>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={ocean3}></img>
          </div>
          <br/>

          <h2 className='h2'>Current version with lights inside</h2>
          <div style={{width:'100%', display:'flex', alignItems: 'center', justifyContent:'center', flexDirection:'row'}}>
            <img style={{minHeight:'10vh',maxHeight:'28vw', display:'inline-block', padding:'5px'}} src={ocean4}></img>
            <img style={{minHeight:'10vh',maxHeight:'28vw', display:'inline-block',padding:'5px'}} src={ocean5}></img>
          </div>
          <br/>

          <h2 className='h2'>Final product render</h2>
          <p className='p'>
          The piece consists of multiple pyramid-shaped objects with holes cut
          out in the shape of the ocean surface. Each pyramid contains an LED light inside and hangs
          from a stable fixture.
          <br/><br/>
          A fully-scaled version of this project would incorporate dozens of pyramids suspended in an
          area with the collective lights casting shadows of the ocean surface.
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={ocean6}></img>
          </div>

          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default OceanProjectPage;
