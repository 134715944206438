// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import {Helmet} from "react-helmet";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';

import ScrollButton from '../../Components/ScrollButton';

// css
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css';
// import PasswordComponent from '../../Components/PasswordComponent';


class EsaiProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/esai_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#247DC7'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <ScrollButton />
      <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}}>
        <HeaderBar colorset="dark" />
      </div>
      <div className='project-detail-page-layout'>
        {/* Title */}
        <h1 className="h1">Esai's Table</h1>
        <h2 className="h2">
        In <a target="_blank" href="https://www.jagproductionsvt.com/esaistable" style={{color: this.accent_color}}>Esai's Table</a>,
        destiny meets eternity for three young black men atop an ancient magical table in
        this mythic tale of black lives, friendship, family, and love. The stage play premiered in 2019
        at Briggs Opera House with plans for an Off-Broadway premiere at Cherry Lane Theatre.
        I created animations to be projected on the stage set.


        </h2>

        {/* Tags */}
        <div className='project-detail-tag-layout'>
          {this.renderTags(["Motion"])}
        </div>

        {/* Background */}
        <h2 className='h2'>Background</h2>
        <p className='p'>
        In Esai's Table, destiny meets eternity for three young black men atop an ancient magical table in
        this mythic tale of black lives, friendship, family, and love. The play is co-produced by Vermont's
        <a target="_blank" href="https://www.jagproductionsvt.com/" style={{color: this.accent_color}}> JAG Productions</a> and
        <a target="_blank" href="https://www.cherrylanetheatre.org/" style={{color: this.accent_color}}> Cherry Lane Theatre </a>
        in New York City. The play was written by
        <a target="_blank" href="https://www.nathanyungerberg.com/" style={{color: this.accent_color}}> Nathan Yungerberg. </a>

        The JAG team approached Dartmouth's
        <a target="_blank" href="http://dali.dartmouth.edu/" style={{color: this.accent_color}}> DALI Lab</a> about working with
        their illustrator,
        <a target="_blank" href="http://patiencelekien.com/" style={{color: this.accent_color}}> Patience Lekien</a> to create
        animations that would be projected on the stage set.


        </p>

        {/* <PasswordComponent
        sha256={'23e92dfba8fb0c93cfba31ad2962b4e35a47054296d1d375d7f7e13e0185de7a'}
        color={this.accent_color}> */}

        {/* Graphical list idk */}
        <ProjectDetailListComponent
        color={this.accent_color}
        titles={["Time frame", "Role", "Skills", "Team"]}
        textFields={["August 2019 - March 2020",
                      "Designer",
                      "After Effects\nPhotoshop\nIllustrator\nAnimation\nSet design",
                      "Mira Ram\nKaitlyn Hahn"]}
        />

        <h2 className='h2'>Mr. Jones</h2>
          <p className='p'>
          Below is one of the animations I created for the project. The decision to include animations of this
          particular style within the play was related to one of the characters, Michael, who is passionate about
          drawing comics. Michael's infant son is the subject of his comics and within the surreal story of this play,
          his comics turn into animations about his family and the families of his two companions: David and Adam. This particular
          animation features a conversation between David (portrayed by an actor) and his father Mr. Jones (depicted with
          this animation projected on the back wall of the set).
          </p>
        <iframe
          width="100%"
          height="480"
          src="https://www.youtube.com/embed/wS-mW7LYEVs"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>


        <p></p>
        {/* </PasswordComponent> */}
      </div>
      <Footer/>
    </div>
    )
  }
}

export default EsaiProjectPage;
