// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import theatre1 from '../../assets/theatre/theatre1.png';
import theatre2 from '../../assets/theatre/theatre2.png';
import theatre3 from '../../assets/theatre/theatre3.png';
import theatre4 from '../../assets/theatre/theatre4.png';
import theatre5 from '../../assets/theatre/theatre5.jpeg';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class MeshProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/mesh_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#00A6CB'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Mesh Subdivider</h1>
          <h2 className="h2">
          A simple mesh subdivider algorithm. Run the project
          <a target="_blank" style={{color: this.accent_color}} href="https://github.com/miramints/graphics-projects"> here</a>.

          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["3D","Development"])}
          </div>

           {/* Graphical list idk */}
           {/* <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["June - September 2020\n(4 months)",
                         "Product Manager\nDesigner",
                         "User Research\nRapid Iteration\nScoping\nMarketing\nFigma",
                         "Mira Ram"]}
          /> */}

          {/* Background */}
          <h2 className='h2'>Overview</h2>
          <p className='p'>
          A simple mesh subdivider algorithm implemented with C++ and Eigen. In addition to implementing this
          algorithm, I also modeled a pair of headphones in Maya with a low-poly coarse mesh to subdivide.
          <a target="_blank" style={{color: this.accent_color}} href="https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/thesis-10.pdf"> Algorithm referenced. </a>
          Run the project and many others
          <a target="_blank" style={{color: this.accent_color}} href="https://github.com/miramints/graphics-projects"> here</a> (contact me for access to the github repository).

          </p>

          <h2 className='h2'>Algorithm</h2>
          <p className='p'>This algorithm takes an object with a triangle mesh as an input
          and outputs a more refined, subdivided mesh. We can represent this object input with two arrays:
          old_vtx and old_tri, which store the certices and triangles of the original mesh respectively.
          For the output mesh, we store the vertices and triangles in the initally empty new_vtx and new_mesh.  </p>
          <ol className='ul'>
            <li className='li'>For each triangle in old_vtx, add 3 midpoint vertices into new_vtx and 4
            new subdivided triangles into new_tri</li>
            <li className='li'>Update the position of an odd vertex by calculating the weighted
            average of 2 endpoint vertices on its subdividing edge and the 2 opposite vertices on
            the 2 triangles sharing this edge. All 4 vertices are from the original mesh </li>
            <li className='li'>Update the position of an even vertex by calculating the weighted average
            of its one ring of incident vertices on the original mesh and itself</li>
          </ol>

          <p></p>



        </div>
        <Footer/>
      </div>
    )
  }
}

export default MeshProjectPage;
