import React from "react";
import { isMobile, isSafari } from 'react-device-detect'
import Logo from '../../assets/mira_logo.svg';
import StarBG from '../../assets/landing_page_2/stars.svg';
import Car from '../../assets/landing_page_2/car2.gif';
import Mira from '../../assets/landing_page_2/mira_portfolio_animation.gif';
import Telescope from '../../assets/landing_page_2/telescope.gif';
import Title from '../../assets/landing_page_2/logo.svg';

import './LandingCard.scss';
import '../../Styles/Fonts.scss';
import SlidingButton2 from "../../Components/SlidingButton2";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import TranslateButton from "../../Components/TranslateButton/TranslateButton";
import ScrollDownButton from "./ScrollDownButton";
import { checkMobile } from "../../Styles/ScreenCalcs";

class ParallaxChild extends React.Component {
    constructor(props) {
        super(props);
    }

    calcPositioning = () => {
        let style = {}
        let mode = this.props.mode;
        if(mode == null) {
            mode = "px"
        }
        if(this.props.left != null) {
            style.left = (this.props.left + this.props.xMaxDrift * this.props.xDriftAmount) + mode;
        } else { // assume right
            style.right = (this.props.right + -this.props.xMaxDrift * this.props.xDriftAmount) + mode;
        }
        if(this.props.top != null) {
            style.top = (this.props.top + this.props.yMaxDrift * this.props.yDriftAmount) + mode
        } else { // assume bot
            style.bottom = (this.props.bottom + -this.props.yMaxDrift * this.props.yDriftAmount) + mode
        }
        return style;
    }

    render() {
        return (
            <div className="parallax-child" style={this.calcPositioning()}>
                {this.props.children}
            </div>
        )
    }
}

export default class LandingCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: checkMobile(),
            mouseXPct: 0,
            mouseYPct: 0,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateLayout);
        window.addEventListener("mousemove", this.handleMouseMove);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateLayout);
        window.removeEventListener("mousemove", this.handleMouseMove);
    }

    updateLayout = () => {
        this.setState({ isMobile: checkMobile() });
    }

    handleMouseMove = (ev) => {
        const { innerWidth: width, innerHeight: height } = window;
        const midPointX = width / 2;
        const midPointY = height / 2;
        const mouseX = ev.clientX;
        const mouseY = ev.clientY;
        const xPct = (mouseX - midPointX) / midPointX;
        const yPct = (mouseY - midPointY) / midPointY;
        this.setState({
            mouseXPct: xPct,
            mouseYPct: yPct
        })
    }

    render() {
        if(this.state.isMobile) {
            return this.mobileRender();
        }
        return this.normalRender();
    }

    mobileRender() {
        return (
            <div className="mobile-card">
                <div className="landing-card-container">
                <div className="card-center-section">
                    <img className ='title-logo' src={Title} />
                    <p className='font-large'>I design elegant & impactful solutions using my background in computer science, digital arts, and psychology </p>
                    <div className="button-section">
                        <div className="landing-card-button font-small">
                            <TranslateButton href='/work'
                                left_icon={ArrowRight}
                                middle_text="Check out my work"
                                right_icon={ArrowRight}
                                color="light-pink"
                            />
                        </div>
                        <div className="mobile-scroll-down-section">
                            <div className="button-between-text font-medium">or scroll for featured projects</div>
                            <div className="landing-card-button-2">
                                <ScrollDownButton />
                            </div>
                        </div>
                  
                    </div>
                    <div className="mobile-telescope-wrapper">
                    <img className="non-interactable telescope-mira telescope" src={Telescope}/>
                    <img className="non-interactable telescope-mira miragif" src={Mira}/>
                    </div>
                    
                    
                </div>

                <ParallaxChild
                    left={0}
                    top={-1}
                    xMaxDrift={2.5}
                    yMaxDrift={2.5}
                    mode='%'
                    xDriftAmount={this.state.mouseXPct}
                    yDriftAmount={this.state.mouseYPct}
                    >
                    <img className="non-interactable star-field" src={StarBG} />
                </ParallaxChild>
                
            </div>

            </div>
            
        )
    }

    normalRender() {
        return (
            <div className="landing-card-container">
                <div className="card-center-section">
                    <img className ='title-logo' src={Title} />
                    <p className='font-large'>I design elegant & impactful solutions using my background in computer science, digital arts, and psychology </p>
                    <div className="button-section">
                        <div className="landing-card-button font-medium">
                            <TranslateButton href='/work'
                                left_icon={ArrowRight}
                                middle_text="Check out my work"
                                right_icon={ArrowRight}
                                color="light-pink"
                            />
                        </div>
                        <div className="button-between-text font-medium">or scroll for featured projects</div>
                        <div className="landing-card-button-2">
                            <ScrollDownButton />
                        </div>
                    </div>
                </div>

                <ParallaxChild
                    left={0}
                    top={-1}
                    xMaxDrift={2.5}
                    yMaxDrift={2.5}
                    mode='%'
                    xDriftAmount={this.state.mouseXPct}
                    yDriftAmount={this.state.mouseYPct}
                    >
                    <img className="non-interactable star-field" src={StarBG} />
                </ParallaxChild>
                <ParallaxChild
                    left={-1}
                    bottom={-1}
                    xMaxDrift={0.5}
                    yMaxDrift={0.5}
                    mode='%'
                    xDriftAmount={this.state.mouseXPct}
                    yDriftAmount={this.state.mouseYPct}
                >
                    <img className="non-interactable car" src={Car}/>
                </ParallaxChild>
                <ParallaxChild
                    right={-8}
                    bottom={-3}
                    xMaxDrift={0.5}
                    yMaxDrift={0.5}
                    mode='%'
                    xDriftAmount={this.state.mouseXPct}
                    yDriftAmount={this.state.mouseYPct}
                >
                    <img className="non-interactable telescope-mira telescope" src={Telescope}/>
                    <img className="non-interactable telescope-mira miragif" src={Mira}/>
                    
                </ParallaxChild>
            </div>
        );
    }
}