// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import designs from '../../assets/soundboard/soundboard_mocks.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class UnleashedProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/unleashed_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#D473D1'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Unleashed VR</h1>
          <h2 className="h2">
          <a style={{color: this.accent_color}}> Unleashed </a>
          is a set of therapeutic experiences for the Oculus Quest. It was created as 3-week final
          project for Dartmouth's VR/AR Design & Development course.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["XR", "Development", "3D", "Motion"])}
          </div>

           {/* Graphical list idk */}
           <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["October – November 2019 \n(3 weeks)",
                         "Designer",
                         "Unity\nMaya\nBlender\nFigma\nOculus SDK\nC#",
                         "Mira Ram\nJames Lee\nAngi Li"]}
          />

          {/* Background */}
          <h2 className='h2'>About Unleashed</h2>
          <p className='p'>
          Unleashed is a set of therapeutic experiences in VR. At this particular point of time,
          there were little to no applications that took advantage of VR to create immersive, therapeutic
          experiences for users. For this project, my team hoped to create a prototype of the type of
          VR product that might fill this gap. Unleashed consists of three key features: the main area,
          destruction room, and painting room. I designed all of the visual elements for this project.
          View a video of the experience and learn more about the features below.
          </p>

          <iframe
            width="100%"
            height="480"
            src="https://www.youtube.com/embed/LBIbD6N8MTE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

          <h2 className='h2'>Features</h2>
          <h3 className='h3'>Main Area</h3>
          <p className='p'>
          The main area serves as a 'home' for this project. The user enters in on this scene, a snowy
          mountaintop with floating islands, through a mysterious cave. The area is designed to have a
          calming and peaceful atmosphere. Directly in front of the user are two bright holograms on
          tree stumps serving as portals to the painting and destruction rooms.
          </p>

          <h3 className='h3'>Destruction Room</h3>
          <p className='p'>
          In the destruction room, the user is able to break most objects in the scene using a variety
          of means and punch a punching bag. The user is also able to
          reset the room after destroying items. The room is meant to serve as a safe, virtual area to
          relieve anger. Features of this room include:
          </p>
          <ul className='ul'>
            <li className='li'>The user is able to grab most objects in the room with their
            controllers. These objects have realistic physics and can break in multiple
            different ways with haptic feedback and sound effects </li>
            <li className='li'>Objects in the room can be destroyed using various different
            means including: throwing, dropping, force against another object, baseball bat, laser gun</li>
            <li className='li'>The objects in the room can be reset to their original
            states</li>
            <li className='li'>Punching bag with haptic feedback and realistic physics</li>
          </ul>

          <h3 className='h3'>Painting Room</h3>
          <p className='p'>
          In the painting room, the user is able to paint every surface of the area. The room is initially
          completely white, but the user can discover models hidden within the room by painting over the walls.
          Features of this room include:
          <ul className='ul'>
            <li className='li'>Change brush pattern, size, and opacity</li>
            <li className='li'>Use color picker to change paint color</li>
            <li className='li'>Reset the room canvas</li>
          </ul>
          </p>

          <h2 className='h2'>Challenges and Takeaways</h2>
          <p className='p'>
          As a result of the short timeframe for this project, many of the features and models we originally planned
          on including did not make it into the final product. Through the process of working on Unleashed,
          I learned not to underestimate the time it can take to integrate the different parts of a collaborative Unity project.
          In the end, we were very grateful that we had designed our project in a modular format with different features
          designated to different rooms/scenes of the project, making it much easier for us to cut out parts of the
          project we ended up not having time for.
          </p>

          <p className='p'>
          While there is much more we could have done with this project with a longer timeline, we were ultimately
          quite satisfied with the final product. For my work on this project, I was awarded an academic citation
          of excellence in this course: VR/AR Design & Development.

          </p>
          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default UnleashedProjectPage;
