// libraries
import React from 'react';
import PropTypes from 'prop-types';
import { checkMobile } from "../../../Styles/ScreenCalcs";

// css
import './ProjectDetailPage.css';
import './ProjectAnnouncementComponent.css';

class ProjectAnnouncementComponent extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let mobileStr = ''
    if(checkMobile()){
      mobileStr = '-mobile'
    }
    return (
      <div className={`project-detail-info-box-color project-detail-page-full-width-div${mobileStr} project-announcement-box-container`} style={{backgroundColor: this.props.color + "19"}}>
        <div className='project-announcement-box'>
            <h3 className='h3 project-announcement-h3' style={{color: this.props.color}}>{this.props.title}</h3>

            <h2 className='h2 project-announcement-h2'>{this.props.text}</h2>
        </div>
      </div>
    )
  }
}

ProjectAnnouncementComponent.propTypes = {
    titles: PropTypes.array,
    textFields: PropTypes.array
}

export default ProjectAnnouncementComponent;
