// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import glsl1 from '../../assets/glsl/glsl1.png';
import glsl2 from '../../assets/glsl/glsl2.png';
import glsl3 from '../../assets/glsl/glsl3.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class GLSLProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/glsl_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#00A6CB'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">GLSL Shader</h1>
          <h2 className="h2">
          Simple lambertian and phong shaders implemented with C++ and OpenGL. Run the project
          <a target="_blank" style={{color: this.accent_color}} href="https://github.com/miramints/graphics-projects"> here</a>.

          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["3D","Development"])}
          </div>

           {/* Graphical list idk */}
           {/* <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["June - September 2020\n(4 months)",
                         "Product Manager\nDesigner",
                         "User Research\nRapid Iteration\nScoping\nMarketing\nFigma",
                         "Mira Ram"]}
          /> */}

          {/* Background */}
          <h2 className='h2'>Overview</h2>
          <p className='p'>
          Simple lambertian and phong shader algorithms implemented with C++ and OpenGL (specifically GLSL). In addition to implementing this
          algorithm, I also modeled a pair of headphones in Maya with a low-poly coarse mesh to subdivide.
          <a target="_blank" style={{color: this.accent_color}} href="https://learnopengl.com/Getting-started/Shaders"> Resource referenced. </a>
          Run the project and many others
          <a target="_blank" style={{color: this.accent_color}} href="https://github.com/miramints/graphics-projects"> here</a> (contact me for access to the github repository).

          </p>

          <h2 className='h2'>Results</h2>
          <p className='p'> Lambertian shader featuring a park at night that I modeled in Maya.  </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={glsl1}></img>
          </div>
          <br/>
          <br/>

          <p className='p'> Color cut-out version of the lambertian shader.  </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={glsl2}></img>
          </div>
          <br/>
          <br/>

          <p className='p'> Phong shader featuring a pair of headphones that I modeled in Maya.   </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={glsl3}></img>
          </div>
          <br/>
          <br/>


          <p></p>



        </div>
        <Footer/>
      </div>
    )
  }
}

export default GLSLProjectPage;
