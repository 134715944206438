import React from "react";

export default class FirstHoverDetectComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false
    }
  }

  addTransitionStyle = () => {
    if(!this.state.hovered){
      this.setState({hovered: true})
    }
  }

  animOnStyle = () => {
    if(this.state.hovered) {
        return ' anim-on '
    }
    return ''
  }
}