// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";
import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';
import { ZoomableImg } from './ProjectDetailPageComponents/ZoomableImage';

import ScrollButton from '../../Components/ScrollButton';
// assets
import drawing1 from '../../assets/hobby_illust/drawing1.png';
import drawing2 from '../../assets/hobby_illust/drawing2.png';
import drawing3 from '../../assets/hobby_illust/drawing3.png';
import drawing4 from '../../assets/hobby_illust/drawing4.jpeg';
import drawing5 from '../../assets/hobby_illust/drawing5.jpeg';
import drawing6 from '../../assets/hobby_illust/drawing6.png';
import drawing7 from '../../assets/hobby_illust/drawing7.png';
import drawing8 from '../../assets/hobby_illust/drawing8.jpeg';
import drawing9 from '../../assets/hobby_illust/drawing9.png';
import drawing10 from '../../assets/hobby_illust/drawing10.jpeg';
import drawing11 from '../../assets/hobby_illust/drawing11.png';
import drawing12 from '../../assets/hobby_illust/drawing12.png';
import drawing13 from '../../assets/hobby_illust/drawing13.png';
import drawing14 from '../../assets/hobby_illust/drawing14.jpeg';
import drawing15 from '../../assets/hobby_illust/drawing15.png';
import drawing16 from '../../assets/hobby_illust/drawing16.png';
import drawing17 from '../../assets/hobby_illust/drawing17.jpeg';
import drawing18 from '../../assets/hobby_illust/drawing18.png';
import drawing19 from '../../assets/hobby_illust/drawing19.png';
import drawing20 from '../../assets/hobby_illust/drawing20.png';
import drawing21 from '../../assets/hobby_illust/drawing21.png';
import drawing22 from '../../assets/hobby_illust/drawing22.png';
import drawing23 from '../../assets/hobby_illust/drawing23.png';
import drawing24 from '../../assets/hobby_illust/drawing24.png';
import drawing25 from '../../assets/hobby_illust/drawing25.png';
import drawing26 from '../../assets/hobby_illust/drawing26.png';
import drawing27 from '../../assets/hobby_illust/drawing27.png';
import drawing28 from '../../assets/hobby_illust/drawing28.png';
import drawing29 from '../../assets/hobby_illust/drawing29.png';
import drawing30 from '../../assets/hobby_illust/drawing30.jpeg';
import drawing31 from '../../assets/hobby_illust/drawing31.jpeg';
import drawing32 from '../../assets/hobby_illust/drawing32.jpeg';
import drawing33 from '../../assets/hobby_illust/drawing33.jpeg';
import drawing34 from '../../assets/hobby_illust/drawing34.png';
import drawing35 from '../../assets/hobby_illust/drawing35.jpeg';
import drawing36 from '../../assets/hobby_illust/drawing36.jpeg';
import drawing37 from '../../assets/hobby_illust/drawing37.jpeg';
import drawing38 from '../../assets/hobby_illust/drawing38.jpeg';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';

class HobbyIllustProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/hobby_illust_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#D473D1';
    this.drawings = [
      drawing3,
      drawing4,
      drawing5,
      drawing6,
      drawing7,
      drawing8,
      drawing9,
      drawing1,
      drawing2,
      drawing10,
      drawing11,
      drawing12,
      drawing13,
      drawing14,
      drawing15,
      drawing16,
      drawing17,
      drawing18,
      drawing19,
      drawing20,
      drawing21,
      drawing22,
      drawing23,
      drawing24,
      drawing25,
      drawing26,
      drawing27,
      drawing28,
      drawing29,
      drawing30,
      drawing32,
      drawing33,
      drawing31,
      drawing34,
      drawing35,
      drawing36,
      drawing38,
      drawing37,
    ]
    this.drawings = this.drawings.reverse()
  }
  // <div className="gallery-container gallery-container-margin">


  //               <a target="_blank" href={drawing34}>
  //                 <img src={drawing34}/>
  //               </a>

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  getImageCols() {
    let numCols = Math.floor(Math.min(3, Math.max(this.state.width / 450 + 1, 1)));
    let cols = [...Array(numCols)].map(e => Array(0));
    for(let i = 0; i < cols.length; i++){
      for(let j = i; j < this.drawings.length; j += numCols){
        cols[i].push(this.drawings[j])
      }
    }
    return cols.map( (drawings) => (
      <div className='gallery-column'>
        {drawings.map(image => (
          <ZoomableImg key={image} src={image} />
        ))}
      </div>
    ));
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark"/>
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}}/>
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Hobby Illustration</h1>
          <h2 className="h2">
          In my spare time, I enjoy drawing my favorite characters. Below are some of my sketches! Click on
          any image to enlarge.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["Illustration"])}
          </div>


          {/* Background */}
          <div className='gallery-container2'>
            {this.getImageCols()}
          </div>
          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default HobbyIllustProjectPage;
