// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import comics1 from '../../assets/comics/comics1.png';
import comics2 from '../../assets/comics/comics2.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class ComicsProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/comics_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#D473D1'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark"/>
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}}/>
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Comics</h1>
          <h2 className="h2">
          Below are a few comics that I've illustrated.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["Illustration"])}
          </div>


          {/* Background */}
          <h2 className="h2"> Undead </h2>
          <p className='p'>Undead is a comic about a teenage girl who realizes that she has transformed into a vampire.
          The comic follows her struggles as she tries to
          overcome the worst parts of being a vampire. You can read the comic
          <a target="_blank" href="https://drive.google.com/drive/u/0/folders/1BZwOPzcQ9guPFYYrF8805YqYt5JlKUTd" style={{color: this.accent_color}}> here</a>.
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={comics1}></img>
          </div>
          <br/>
          <h2 className="h2"> Exam Boasting: Editorial Cartoon </h2>
          <p className="p">Inspired by the often toxic attitudes of Dartmouth students regarding grades and test performance.
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'100%', display:'inline-block'}} src={comics2}></img>
          </div>
          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default ComicsProjectPage;
