import React from "react";
import Pagination from 'react-bootstrap/Pagination'
import { isSafari } from 'react-device-detect'
import ReactPageScroller from "react-page-scroller";

import ScrollButton from '../../Components/ScrollButton';

// libraries
import { CardImage } from './CardImages';

// assets
import flourishFull from '../../assets/flourish/flourish_full.png'
import flourishTop from '../../assets/flourish/flourish_top.png'
import flourishMid from '../../assets/flourish/flourish_mid.png'
import flourishBot from '../../assets/flourish/flourish_bot.png'

import campfireFull from '../../assets/campfire/campfire_full.png'
import campfireTop from '../../assets/campfire/campfire_top.png'
import campfireMid from '../../assets/campfire/campfire_mid.png'
import campfireBot from '../../assets/campfire/campfire_bot.png'

import unmaskedFull from '../../assets/unmasked/unmasked_full.png'
import unmaskedTop from '../../assets/unmasked/unmasked_top.png'
import unmaskedMid from '../../assets/unmasked/unmasked_mid.png'
import unmaskedBot from '../../assets/unmasked/unmasked_bot.png'

// imports for final card images
import perfume from '../../assets/everything_else/perfume.png'
import render from '../../assets/everything_else/3d_render.png'
import vr from '../../assets/everything_else/vr.png'
import plant from '../../assets/everything_else/plant_desktop.png'

// css
import './LandingPage.css';

// components
import { LandingPageCard } from './LandingPageCard';
import { FinalCard } from './LandingPageCard';
import HelloCard from './HelloCard';
import { checkMobile } from "../../Styles/ScreenCalcs";

const NUM_CHILDREN = 4;

export default class FullPage extends React.Component {
  constructor(props) {
    super(props);
    this.topref = React.createRef();
    this.childRefs = []
    for (let i = 0; i < NUM_CHILDREN; i++) {
      this.childRefs.push(React.createRef())
    }
    // for last card
    this.childRefs.push(React.createRef())
    this.childRefs.push(React.createRef())
    this.childRefs.push(React.createRef())
    this.childRefs.push(React.createRef())

    this.currentIndex = -1
    this.state = {
      helloRendered: false,
      canScroll: false,
      currentPage: 0,
      // this is for updating the side selector which has some weird timing
      currentSelected: 0,
      isMobile: checkMobile()
    }
  }

  scrollToFirstChild = () => {
    this.topref.current.scrollIntoView({behavior: 'smooth'})
  }

  scrollToFirstChildNonMobile = () => {
    this.setState({
      currentSelected: 1
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateLayout);

    // preload all image assets
    const img = new Image();
    img.src = campfireBot; // by setting an src, you trigger browser download
    img.src = campfireMid; // by setting an src, you trigger browser download
    img.src = campfireTop; // by setting an src, you trigger browser download
    img.src = unmaskedBot; // by setting an src, you trigger browser download
    img.src = unmaskedMid; // by setting an src, you trigger browser download
    img.src = unmaskedTop; // by setting an src, you trigger browser download
    img.src = flourishBot; // by setting an src, you trigger browser download
    img.src = flourishMid; // by setting an src, you trigger browser download
    img.src = flourishTop; // by setting an src, you trigger browser download
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayout);
  }

  updateLayout = () => {
    this.setState({ isMobile: checkMobile() });
  }

  handlePageChange = number => {
    if(number > this.NUM_CHILDREN || number < 0) {
      return
    }
    this.state.currentSelected = number
    this.setState({
      currentPage: number
    })
  };

  selectPage = number => {
    this.setState({
      currentSelected: number
    })
  }

  onHelloPageRender() {
    this.setState({
      helloRendered: true,
      canScroll: true,
    })
  }

  getPagesNumbers = () => {
    const pageNumbers = [];
    for (let i = 0; i <= NUM_CHILDREN; i++) {
      if (this.state.currentPage == i) {
        pageNumbers.push(
          <Pagination.Item className={"page-item item-active"}  key={i} eventKey={i} onClick={() => this.selectPage(i)}/>
        );
      } else {
        pageNumbers.push(
          <Pagination.Item className={"page-item item-inactive"}  key={i} eventKey={i} onClick={() => this.selectPage(i)}/>
        );
      }
    }

    return [...pageNumbers];
  };

  handleBeforePageChange = number => {
    if(!this.state.helloRendered) {
      return
    }
    let refIndex = number - 1;
    if(this.currentIndex != -1 && this.currentIndex < this.childRefs.length) {
      if(this.childRefs[this.currentIndex].current){
        this.childRefs[this.currentIndex].current.toggleImage(false);
      }
    }
    if (refIndex < 0 || refIndex >= 5) {
      return;
    }
    this.currentIndex = refIndex;
    if(this.childRefs[this.currentIndex].current){
      this.childRefs[this.currentIndex].current.toggleImage(true);
    }
    if(this.currentIndex == 3) {
      for(let i = 3; i <=6; i++){
        if(this.childRefs[i].current)
        {
          this.childRefs[i].current.toggleImage(true)
        }
      }
    } else {
      for(let i = 3; i <=6; i++){
        if(this.childRefs[i].current)
        {
          this.childRefs[i].current.toggleImage(false)
        }
      }
    }
  };

  render() {
    if (isSafari && !this.state.isMobile) {
      return this.safariRender();
    }
    else if(!this.state.isMobile) {
      return this.normalRender();
    }
    else {
      // reset normals
      this.state = {
        currentPage: 0,
        // this is for updating the side selector which has some weird timing
        currentSelected: 0,
      }
      return this.mobileRender();
    }
  }

  mobileRender() {
    return (
      <div>
        <ScrollButton />
        <HelloCard
          // performance purposes
          onClick={this.scrollToFirstChild}
          isMobile={true}
          onRender={this.onHelloPageRender.bind(this)}
        />
        <div ref={this.topref}/>
        <LandingPageCard
          imageDecos={[
                        <div id='campfire' className='transition-rectangle'/>
                      ]}
          title='Campfire'
          subtitle='UX Designer • Ongoing'
          blurb='is an enterprise cross-platform tool for 3D collaboration.
            It features simple tools for communicating complex concepts in design reviews, training, and more.'
          projectButtonText='case study'
          href='/campfire'
          protoButtonText='prototype'
          color='#F84536'
          cssId='campfire'
          isMobile={true}
          mobileImage={campfireFull}
        />
        <LandingPageCard
          imageDecos={[
                        <div id='flourish' className='transition-rectangle'/>
                      ]}
          title='Flourish'
          subtitle='Lead Designer • Ongoing'
          blurb='is an app designed to help patients with multi-symptom chronic illnesses better track, visualize, and
                communicate their symptoms and triggers over time. The app is currently in beta.'
          projectButtonText='case study'
          href='/flourish'
          protoButtonText='prototype'
          cssId='flourish'
          color='#8093F1'
          isMobile={true}
          mobileImage={flourishFull}
        />
          <LandingPageCard
          imageDecos={[
                        <div id='flourish' className='transition-rectangle'/>
                      ]}
          title='Unmasked'
          subtitle="Head of Product • Ongoing"
          blurb='is an anonymous social media app for college students focused on mental health.
                The app is available on the iPhone and Android app stores.'
          projectButtonText='case study'
          href='/unmasked'
          cssId='unmasked'
          color='#926BE4'
          protoButtonText='prototype'
          isMobile={true}
          mobileImage={unmaskedFull}
        />
        <FinalCard
            image1={plant}
            image2={render}
            image3={vr}
          projectButtonText='check out my work'
          href='/work'
          color='#D473D1'
          isMobile={true}
          mobileImage={unmaskedFull}
        />
      </div>
    )
  }

  normalRender() {
    let pagesNumbers = this.getPagesNumbers();
    return (
      <div>
        <ReactPageScroller
          pageOnChange={this.handlePageChange}
          onBeforePageScroll={this.handleBeforePageChange}
          animationTimerBuffer={500}
          renderAllPagesOnFirstRender={true}
          customPageNumber={this.state.currentSelected}
        >
          <HelloCard
            // performance purposes
            onClick={this.scrollToFirstChildNonMobile}
            onRender={this.onHelloPageRender.bind(this)}
          />

          {/* campfire */}
          <LandingPageCard
            imageDecos={[
                          <div id='campfire' className='transition-rectangle'/>
                        ]}
            title='Campfire'
            subtitle='UX Designer • Ongoing'
            blurb='is an enterprise cross-platform tool for 3D collaboration.
            It features simple tools for communicating complex concepts in design reviews, training, and more.'
            projectButtonText='case study'
            protoButtonText='prototype'
            isImageOnRight={true}
            href='/campfire'
            image={<CardImage
                      ref={this.childRefs[0]}
                      topImage={campfireTop}
                      midImage={campfireMid}
                      botImage={campfireBot}/>}
            cssId='campfire'
            color='#F84536'
          />

          {/* flourish */}
          <LandingPageCard
            imageDecos={[
                          <div id='flourish' className='transition-rectangle'/>
                        ]}
            title='Flourish'
            subtitle='Lead Designer • 2020-2023'
            blurb='is an app designed to help patients with multi-symptom chronic illnesses better track, visualize, and
                  communicate their symptoms and triggers over time. The app is currently in beta.'
            projectButtonText='case study'
            protoButtonText='prototype'
            isImageOnRight={false}
            href='/flourish'
            image={<CardImage
                      ref={this.childRefs[1]}
                      topImage={flourishTop}
                      midImage={flourishMid}
                      botImage={flourishBot}/>}
            cssId='flourish'
            color='#8093F1'
          />

          {/* unmasked */}
          <LandingPageCard
            imageDecos={[
                          <div id='unmasked' className='transition-rectangle'/>
                        ]}
            title='Unmasked'
            subtitle="Head of Product • 2018-2023"
            blurb='is an anonymous social media app for college students focused on mental health.
                  The app is available on the iPhone and Android app stores.'
            projectButtonText='case study'
            protoButtonText='prototype'
            isImageOnRight={true}
            href='/unmasked'
            image={<CardImage
                      ref={this.childRefs[2]}
                      topImage={unmaskedTop}
                      midImage={unmaskedMid}
                      botImage={unmaskedBot}/>}
            cssId='unmasked'
            color='#926BE4'
          />

          {/* end */}
          <FinalCard
            projectButtonText='check out my work'
            href='/work'
            cssId='campfire'
            color='#D473D1'
            image1={<CardImage
                      ref={this.childRefs[3]}
                      topImage={perfume}/>}
            image2={<CardImage
                      ref={this.childRefs[4]}
                      topImage={render}/>}
            image3={<CardImage
                      ref={this.childRefs[5]}
                      botImage={vr}/>}
            image4={<CardImage
                      ref={this.childRefs[6]}
                      botImage={plant}/>}
          />
        </ReactPageScroller>
        <Pagination className="pagination-additional-class" bsSize="">
          {pagesNumbers}
        </Pagination>
      </div>
    );
  }

  safariRender() {
    return (
      <div>
        <HelloCard
          // performance purposes
          onClick={this.scrollToFirstChild}
          onRender={this.onHelloPageRender.bind(this)}
        />

        {/* campfire */}
        <LandingPageCard
          imageDecos={[
                        <div id='campfire' className='transition-rectangle'/>
                      ]}
          title='Campfire'
          subtitle='UX Designer • Ongoing'
          blurb='is an enterprise cross-platform tool for 3D collaboration.
            It features simple tools for communicating complex concepts in design reviews, training, and more.'
          projectButtonText='case study'
          protoButtonText='prototype'
          isImageOnRight={true}
          href='/campfire'
          image={<CardImage
                    ref={this.childRefs[0]}
                    topImage={campfireTop}
                    midImage={campfireMid}
                    botImage={campfireBot}/>}
          cssId='campfire'
          color='#F84536'
        />

        <LandingPageCard
          imageDecos={[
                        <div id='flourish' className='transition-rectangle'/>
                      ]}
          title='Flourish'
          subtitle='Lead Designer • Ongoing'
          blurb='is an app designed to help patients with multi-symptom chronic illnesses better track, visualize, and
                communicate their symptoms and triggers over time. The app is currently in beta.'
          projectButtonText='case study'
          protoButtonText='prototype'
          isImageOnRight={false}
          href='/flourish'
          image={<CardImage
                    ref={this.childRefs[1]}
                    topImage={flourishTop}
                    midImage={flourishMid}
                    botImage={flourishBot}/>}
          cssId='flourish'
          color='#8093F1'
        />

        {/* unmasked */}
        <div ref={this.topref}/>
        <LandingPageCard
          imageDecos={[
                        <div id='unmasked' className='transition-rectangle'/>
                      ]}
          title='Unmasked'
          subtitle="Head of Product • Ongoing"
          blurb='is an anonymous social media app for college students focused on mental health.
                The app is available on the iPhone and Android app stores.'
          projectButtonText='case study'
          protoButtonText='prototype'
          isImageOnRight={true}
          href='/unmasked'
          image={<CardImage
                    ref={this.childRefs[3]}
                    topImage={unmaskedTop}
                    midImage={unmaskedMid}
                    botImage={unmaskedBot}/>}
          cssId='unmasked'
          color='#926BE4'
        />

        {/* end */}
        <FinalCard
          projectButtonText='check out my work'
          cssId='campfire'
          href='/work'
          color='#D473D1'
          image1={<CardImage
                    ref={this.childRefs[3]}
                    topImage={perfume}/>}
          image2={<CardImage
                    ref={this.childRefs[4]}
                    topImage={render}/>}
          image3={<CardImage
                    ref={this.childRefs[5]}
                    botImage={vr}/>}
          image4={<CardImage
                    ref={this.childRefs[6]}
                    botImage={plant}/>}
        />
      </div>
    );
  }
}