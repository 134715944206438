// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import theatre1 from '../../assets/theatre/theatre1.png';
import theatre2 from '../../assets/theatre/theatre2.png';
import theatre3 from '../../assets/theatre/theatre3.png';
import theatre4 from '../../assets/theatre/theatre4.png';
import theatre5 from '../../assets/theatre/theatre5.jpeg';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class TheatreProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/theatre_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#E03950'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Theatre VR</h1>
          <h2 className="h2">
          <a style={{color: this.accent_color}}> Theatre VR </a>
          is a multi-user virtual-reality platform for the practice and performance of Black theatre in a culturally inclusive environment.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["XR", "Development", "3D","UI/UX"])}
          </div>

           {/* Graphical list idk */}
           {/* <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["June - September 2020\n(4 months)",
                         "Product Manager\nDesigner",
                         "User Research\nRapid Iteration\nScoping\nMarketing\nFigma",
                         "Mira Ram"]}
          /> */}

          {/* Background */}
          <h2 className='h2'>Background</h2>
          <p className='p'>
          Theatre VR is a multi-user virtual-reality platform for the practice and performance of Black theatre
          in a culturally inclusive environment created under
          <a target="_blank" style={{color: this.accent_color}} href="https://theater.dartmouth.edu/people/monica-white-ndounou"> Professor Monica Ndounou</a>
          . Users have access to a practice room and several theatrical
          stages, and can select avatars and customize their features. Users can also access a script reading feature and stage special effects for
          virtual performances. Currently, the space is designed as a
          studio/lab for the courses taught by Professor Ndounou in the Theatre Department at Dartmouth College.
          </p>

          <p></p>
          <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["January – March 2021\n(3 mo)",
                         "Designer",
                         "Wireframing\nPrototyping\n3D Interaction Design\nFigma\nUnity\nMaya",
                         "Mira Ram\nQiyao Zuo\nJulian Wu\nRicardo Almazan"]}
          />

          <h2 className='h2'>The Problem</h2>
          <p className='p'>
          Currently, the few spaces that allow for remote theatrical storytelling do not take into account
          the cultural specificity of Black theatre artists and plays, and do not offer a space for both
          creative practice for performers and viewing experience for audiences. Thus, there is a greater
          need for a virtual platform that teachers, students, directors, actors, writers, and the audience
          can learn, practice, produce, and visualize Black theatre.
          </p>

          <p className='p'>
          Theatre VR is a multi-user virtual-reality platform for the practice and performance of Black theatre
          in a culturally inclusive environment. Users have access to a practice room and several theatrical
          stages, and can select avatars and customize their features. The space was originally designed as a
          studio/lab for the course “Black Theatre, USA” in the Theatre Department at Dartmouth College.
          The project is now being integrated into Dartmouth's
          <a target="_blank" style={{color: this.accent_color}} href="/silvr"> SILvr project </a>.
          </p>

          <br/>
          <h2 className='h2'>Process</h2>
          <p className='p'>
          All models were created in Autodesk Maya and Blender. All scenes and game objects are networked with
          Unity Photon Network and were developed through C# scripts. All UI elements were either handcrafted in
          Figma or built upon ModernUIPack for Unity to provide the user with a clear and easy interface.
          </p>

          <br/>
          <h2 className='h2'>Scenes</h2>
          <p className='p'>
          The platform includes various different 3D environments for users to perform in. Below is a preview of some of our different scenes:
          </p>

          <h3 className='h3'>Opening scene</h3>
          <p className='p'>
          What users see when they first enter the platform
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'100%', display:'inline-block'}} src={theatre1}></img>
          </div>
          <br/>

          <h3 className='h3'>Arena stage</h3>
          <p className='p'>
          An arena-style stage for online multi-user performances
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'100%', display:'inline-block'}} src={theatre2}></img>
          </div>
          <br/>

          <h3 className='h3'>Practice room</h3>
          <p className='p'>
          A sparse practice room for users to either practice on their own or with others. The wardrobe can be
          used to customize the user's avatar appearance.
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'100%', display:'inline-block'}} src={theatre3}></img>
          </div>
          <br/>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'100%', display:'inline-block'}} src={theatre4}></img>
          </div>
          <br/>

          <h3 className='h3'>Open field</h3>
          <p className='p'>
          An outdoor environment featuring a vast open field.
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'100%', display:'inline-block'}} src={theatre5}></img>
          </div>
          <br/>

        </div>
        <Footer/>
      </div>
    )
  }
}

export default TheatreProjectPage;
