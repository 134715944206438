// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import designs from '../../assets/soundboard/soundboard_mocks.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class LanguageProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/language_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#D99BFF'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Language Data</h1>
          <h2 className="h2">
          <a target="_blank" href="https://www.youtube.com/watch?v=mXC3HTpIkz8&feature=youtu.be" style={{color: this.accent_color}}> Language Data </a>
          is a short video on language loss made for 1-week data visualization project. Read about our reserach and data analysis
          <a target="_blank" href="https://github.com/ContextLab/storytelling-with-data/blob/master/data-stories/language-shift/language-shift.md" style={{color: this.accent_color}}> here</a>.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["Motion", "Development", "Graphic Design", "Illustration"])}
          </div>

          {/* Background */}
          <h2 className='h2'>Overview</h2>
          <p className='p'>
          Linguists have noticed a strange phenomenon dubbed third generation shift. Most immigrant groups
          switch to using English as their primary language by the 3rd generation. We found an interesting
          dataset that covers three generations of multiple Asian and Hispanic ethnicities and their language
          proficiency in various U.S. cities. We explored this dataset in order to compare and contrast language
          shift in Asian and Hispanic populations.
          </p>

          <p className='p'>
          Although Asian immigrants had higher English Only scores than Hispanic immigrants in every generation,
          if one investigates how all the ethnicities within these broad categories, they will find that the
          trends within the groups vary greatly.
          </p>

          <p className='p'>
          In conclusion, Asian and Hispanic are broad categories that fit very different and diverse cultures
          within them. To understand the third generation shift, one must look at individual ethnicities rather
          than these broad labels.
          </p>

           {/* Graphical list idk */}
           <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["May 2020 \n(1 week)",
                         "Designer + Developer",
                         "After Effects\nFigma\nPython\nJupyter Notebooks\nData Analysis\nData Visualization",
                         "Mira Ram\nBill Tang\nMaddy Lee\nJae Hong"]}
          />
          <iframe
            width="100%"
            height="560"
            src="https://www.youtube.com/embed/mXC3HTpIkz8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>

            </iframe>

            <h2 className='h2'>Losing your language is sad</h2>
            <p className='p'>
            The video above was created as a part of a 1-week project for my Psychology Senior Seminar: Storytelling
            with Data. All video content was created by me. The content of this video was informed by out data
            analysis, which can be found
            <a target="_blank" href="https://github.com/ContextLab/storytelling-with-data/blob/master/data-stories/language-shift/language-shift.md" style={{color: this.accent_color}}> here</a>.
            </p>






          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default LanguageProjectPage;
