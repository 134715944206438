// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';

import ScrollButton from '../../Components/ScrollButton';
// assets
import firefly1 from '../../assets/firefly/firefly1.jpg';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class FireflyProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/firefly_banner.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#00A6CB'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark" />
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}} />
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Firefly Ruins Shaders</h1>
          <h2 className="h2">
          <a target="_blank" style={{color: this.accent_color}} href="https://github.com/miramints/CS177-final.git"> Firefly Ruins Shaders </a>
          is a graphics programming project depicting a mysterious island ruins under a night sky. This
          project was created as a part of Dartmouth's Computer Graphics course.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["3D","Development","Motion"])}
          </div>

           {/* Graphical list idk */}
           {/* <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["June - September 2020\n(4 months)",
                         "Product Manager\nDesigner",
                         "User Research\nRapid Iteration\nScoping\nMarketing\nFigma",
                         "Mira Ram"]}
          /> */}

          {/* Background */}
          <h2 className='h2'>Overview</h2>
          <p className='p'>
          The goal of this project was to learn and implement different graphics algorithms. Algorithms
          implemented include a basic ray tracer, sky sphere, environment mapper, grass generation/rendering,
          bloom, and alpha blending. You can run our project
          <a target="_blank" style={{color: this.accent_color}} href="https://github.com/miramints/CS177-final.git"> here </a>(contact me for access to the github repository).
          </p>

          <p></p>
          <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["March 2021\n(3 weeks)",
                         "Developer",
                         "C++\nC\nOpenGL\nProgramming shaders\nRay tracing\nMaya",
                         "Mira Ram\nWylie Kasai\nTanli Su\nVarsha Iyer"]}
          />

          <h2 className='h2'>Artistic Theme</h2>
          <p className='p'>
          To address the theme of “A reason to love this world,” our team created a scene
          featuring rocky ruins under a starry aurora sky. We were inspired by the works of
          <a target="_blank" style={{color: this.accent_color}} href="http://www.3daysmarch.net/original.php"> Loika/3 Days March </a>
          when deciding on the artistic direction. Although the scene
          features old pillars, we wanted to create a peaceful environment around it through
          the starry aurora sky, fireflies, and peaceful body of water, hence “A reason to love
          this world.” Below is our initial sketch:
          </p>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
          < img style={{maxWidth:'60vw', display:'inline-block'}} src={firefly1}></img>
          </div>

          <br/>

          <h2 className='h2'>Technical Contributions</h2>
          <ul className='ul'>
            <li className='li'>Aurora and Twinkling Stars in a Sky Sphere</li>
            <li className='li'>Animated Water and Environment Mapping</li>
            <li className='li'>Optimized Grass with a Terrain</li>
            <li className='li'>Moving Fireflies with Bloom Effect</li>
            <li className='li'>Modeled Elements: Pillars and Rocks</li>
          </ul> <br/>

          <iframe
            width="100%"
            height="480"
            src="https://www.youtube.com/embed/qBNPFC2yTWs"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

            <br/>
          <h2 className='h2'>Technical Implementation</h2>
          <h3 className='h3'>Aurora and Twinkling Stars in a Sky Sphere</h3>
          <p className='p'>
          We used a sky sphere by putting all of our objects inside a large sphere generated
          analytically. The aurora and stars were generated in the sky sphere shader. The
          colors were calculated only for points with a z-coordinate greater than 0, since we
          only care about the top half of the sky sphere.
          </p>
          <p className='p'>
          The aurora shader mixes 3 colors (green, navy blue, purple) and these three
          colors change over time using the GLSL mix() function. The aurora also uses
          hermite interpolation to blend the colors over time. A sin function is used to create
          motion for the aurora, calculating the distance of the uv coordinates on the
          sphere.
          </p>
          <p className='p'>
          The stars are generated using a perlin noise function. We added a rotation to the
          stars within the hash function, which moves the stars in circles and creates an
          appearance of twinkling.
          </p>

          <br/>
          <h3 className='h3'>Animated Water and Environment Mapping</h3>
          <p className='p'>
          We generated water using a perlin noise function. However, rather than using the
          same hash function used for a terrain, a different one was used to create lower
          peaks and less jagged edges. Using absolute value in our function created
          different peaks than the ones generally used for a terrain (such as power or
          square root). The hash function used for rotating the stars was slightly modified
          for moving the water and creating waves; using sin and cosine, we undulated the
          heights.
          </p>
          <p className='p'>
          We used environment mapping to reflect the color of the sky onto the surface of
          the water and create a more appealing visual effect. This was implemented in the
          fragment shader of the water. We first calculate the ray reflected off the surface
          of the water using the incident ray (the ray from the camera position to the vertex
          position) and the normal ray. We use the GLSL function refract() to calculate the
          refraction, with refractive indices 1.0 for air and 1.33 for water. Then, we calculate
          the position of the point where the reflected ray intersects the sky sphere, and we
          determine the color of that point using the same calculation as for the aurora sky
          sphere shader. Lastly, we add this color to the final color of the water surface.
          </p>

          <br/>
          <h3 className='h3'>Optimized Grass and Perlin Noise Terrain</h3>
          <p className='p'>
          We first built a terrain for the grass to be on using a perlin noise function. This
          terrain was very similar to implementations in
          <a target="_blank" style={{color: this.accent_color}} href="https://github.com/miramints/graphics-projects"> Assignment 3</a>, except that we made
          lower peaks to create a flatter, but textured ground. The mix() function was also
          used to add variations in color for the different darker and lighter elements of the
          ground.
          </p>

          <p className='p'>
          Rather than use a grass mesh model that would quickly become expensive after
          repeated many times, we opted for an intersection of three textured panels to
          create the illusion of tufts of grass. This process starts with three square panels
          that intersect in the middle, with the second and third panels rotated 120º and
          240º degrees around their vertical axis. Then, each panel is UV mapped to the
          same location so that the same texture may be used.
          </p>

          <p className='p'>
          We used a random hash function to procedurally generate many of these grass
          tufts in main.cpp. This low-cost implementation plays a key role in keeping the
          scene rendering fast. We then used the texture() function in the fragment shader
          to map the UV to a loaded albedo texture with the grass. We had to modify the
          Add_Texture_From_File() function to read a 4th channel - the alpha channel - from
          the .png file so that the grass did not have an unwanted background. Once all
          three panels are textured and in the scene, they feign the appearance of 3D grass
          at a much lower cost for the GPU.
          </p>

          <br/>
          <h3 className='h3'>Moving Fireflies with a Bloom Effect</h3>
          <p className='p'>
          We wanted to create realistic, but cost efficient fireflies which would move similarly
          to how real fireflies would, in all directions. After looking at various firefly effects
          on shaders, we stumbled upon a 2D firefly effect which used bloom.
          </p>

          <p className='p'>
          To generate the bloom effect and create a concentrated flow, we used separate
          point lights for each firefly as a 2D particle overlay over the scene based on a
          shadertoy algorithm. Each firefly/sphere position and glow intensity is generated
          using two separate hash functions for the number of spheres specified as a
          constant at the beginning of the fragment shader. The amount of glow (bloom)
          around each sphere point is determined using a distance function.
          </p>

          <p className='p'>
          We decided on using this 2D effect on a vertical plane, instead of using 3D
          spheres, to save rendering time. To see the rest of the scene, we enabled alpha
          values and lowered the opacity of the plane so that it would be transparent.
          </p>

          <br/>
          <h3 className='h3'>Modeled Elements: Pillars and Rocks</h3>
          <p className='p'>
          These elements were modeled in Maya and imported into the scene. The shaders
          for the modeled elements included a normal mapping function based on the
          implementation from
          <a target="_blank" style={{color: this.accent_color}} href="https://github.com/miramints/graphics-projects"> Assignment 3</a>. Colored lighting was also added to match the
          changing colors of the aurora.
          </p>

          <br/>
          <h2 className='h2'>Solved Challenges</h2>
          <h3 className='h3'>Realistic Water</h3>
          <p className='p'>
          We wanted to create realistic water which moved, glimmered and refracted
          similarly to real bodies of water.
          </p>
          <p className='p'>
          We initially tried various hash functions from online to create the perfect water
          effect from perlin noise. We quickly realised that using terrain hash functions
          would create crashing waves when undulating the heights. After trying out various
          techniques, we realized that the absolute value function would create realistic,
          calming water waves. We also lowered the heights of the waves to make the water
          more peaceful looking.
          </p>
          <p className='p'>
          We used ambient, diffuse, and specular lighting for the water. We found that the
          specular lighting was particularly useful for creating realistic water because it
          made the water shimmer nicely, especially when animated. Lastly, using
          environment mapping to reflect the color of the sky onto the water helped us
          create a realistic and visually appealing water surface.
          </p>

          <br/>
          <h3 className='h3'>Depth of Field for the Fireflies</h3>
          <p className='p'>
          Despite rendering our fireflies as particles on a 2D plane, we still wanted to have a
          3D effect with the fireflies and create a sense of depth. To do so, we added 2 sets
          of fireflies. Set 1 of the fireflies moved along the x and z directions. Set 2 of the
          fireflies, however, would also get larger and smaller (using the clamp and sin
          functions) to make it appear as if the fireflies are getting closer and farther. This
          resembled a depth of field.
          </p>

          <br/>
          <h2 className='h2'>Contribution</h2>
          <p className='p'>A list of my contributions for this group project:
          </p>
          <ul className='ul'>
            <li className='li'>Conducted initial scene research and created sketches</li>
            <li className='li'>Implemented fireflies and bloom effect with Varsha</li>
            <li className='li'>Modeled and UV-mapped pillars and rocks in Maya</li>
            <li className='li'>Wrote shaders and main.cpp functions for pillars and rocks</li>
            <li className='li'>Made final video</li>
          </ul>
          <br/>

          <h2 className='h2'>Sources</h2>
          <ul className='ul'>
            <li className='li'>
              <a
                target="_blank"
                style={{color: this.accent_color}}
                href="https://stackoverflow.com/questions/12964279/whats-the-origin-of-this-glsl-rand-one-liner/">
                  Stackoverflow
                </a>
              </li>

              <li className='li'>
              <a
                target="_blank"
                style={{color: this.accent_color}}
                href="https://www.shadertoy.com/view/MlSfzz">
                  Starry sky shadertoy
                </a>
              </li>

              <li className='li'>
              <a
                style={{color: this.accent_color}}
                href="https://developer.download.nvidia.com/books/HTML/gpugems/gpugems_ch07.html">
                  GPU Gems
                </a>
              </li>

              <li className='li'>
              <a
                target="_blank"
                style={{color: this.accent_color}}
                href="https://learnopengl.com/Advanced-OpenGL/Blending">
                  Learn OpenGL: Blending
                </a>
              </li>

              <li className='li'>
              <a
                target="_blank"
                style={{color: this.accent_color}}
                href="https://antongerdelan.net/opengl/cubemaps.html">
                  Dr. Anton Gerdelan: Cube Maps
                </a>
              </li>

              <li className='li'>
              <a
                target="_blank"
                style={{color: this.accent_color}}
                href="https://www.shadertoy.com/view/WscBzX">
                  Flying fireflies shadertoy
                </a>
              </li>

              <li className='li'>
              <a
                target="_blank"
                style={{color: this.accent_color}}
                href="https://3dtextures.me/">
                  3D textures
                </a>
              </li>


          </ul>

        </div>
      </div>
    )
  }
}

export default FireflyProjectPage;
