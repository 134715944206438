import React from "react";

import '../../Styles/Fonts.scss';
import "./TranslateButton.scss";
import FirstHoverDetectComponent from "../SharedLib/FirstHoverDetectComponent";

export default class TranslateButton extends FirstHoverDetectComponent {
  constructor(props) {
    super(props);
  }

  color = () => {
    return " " + this.props.color + " "
  }

  size = () => {
    if(this.props.size == 'small') {
      return ' ' + this.props.size;
    }
    return ' normal';
  }

  render() {
    return (
        <a className={"translate-button" + this.animOnStyle() + this.color()} onMouseEnter={this.addTransitionStyle} href={this.props.href}>
            <div className={"anim-container" + this.size()}>
                <div className="section"><img className="left" src={this.props.left_icon}/></div>
                <div className="section">{this.props.middle_text}</div>
                <div className="section"><img className="right" src={this.props.right_icon}/></div>
            </div>
        </a>
    )
  }
}