// libraries
import React from 'react';
import PropTypes from 'prop-types';
import { isSafari } from 'react-device-detect'
// css
import './LandingPage.css';
import './CardImages.css';

// ================ CARD IMAGE ========================

class CardImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
      scroll: 0,
    }
    this.toggleImage(false)
  }

  toggleImage(isVisible) {
    this.setState({
      isVisible: isVisible
    });
  }

  render() {
    const topAnimationCSS = 'card-image ' + ((this.state.isVisible || isSafari) ? 'topToggleIn' : 'topToggleOut');
    const midAnimationCSS = 'card-image ' + ((this.state.isVisible || isSafari) ? 'midToggleIn' : 'midToggleOut');
    const botAnimationCSS = 'card-image ' + ((this.state.isVisible || isSafari) ? 'botToggleIn' : 'botToggleOut');
    return (
      <div className='card-image-container'>
        <div className='card-image-relative-container'>
          <img src={this.props.topImage} className={'top-image ' + topAnimationCSS}/>
          <img src={this.props.midImage} className={'mid-image ' + midAnimationCSS}/>
          <img src={this.props.botImage} className={'bot-image ' + botAnimationCSS}/>
        </div>
      </div>
    )
  }
}

CardImage.propTypes = {
  // all of these should be imported images
  topImage: PropTypes.any,
  midImage: PropTypes.any,
  botImage: PropTypes.any,
}

export { CardImage };