// libraries
import React from 'react';

// assets
import miragif4 from '../../assets/mira_rectangle.JPG';
import miracircle from '../../assets/mira_circle1.png';

// css
import './AboutMe.scss';
import './AboutMeMobile.scss';
import HeaderBar from '../../Components/HeaderBar';
import { transform } from 'lodash';
import Footer from '../ProjectDetailPage/ProjectDetailPageComponents/Footer';
import { checkMobile } from '../../Styles/ScreenCalcs';

class AboutMe extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isMobile: checkMobile() }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateLayout);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayout);
  }

  updateLayout = () => {
    this.setState({ isMobile: checkMobile() });
  }

  render() {
    if(this.state.isMobile) return this.mobileRender()
    else return this.normalRender()

  }

  mobileRender() {
    return (
      <div>
        <HeaderBar/>
        <div className='about-me-page-layout-mobile'>
          <img style={{width:"25vw"}} src={miracircle}/>
          <h1 style={{textTransform:"lowercase", marginTop:"10px"}}>Mira Ram</h1>
          <div className='about-me-rectangle-mobile'/>
          <p><a href="mailto:mira.ram@gmail.com" style={{color:"grey", fontWeight:"400",textDecoration:"underline"}}>mira.ram(@)gmail(.)com</a></p>
          <p><a href="https://www.linkedin.com/in/miraram/" target="_blank" style={{color:"grey", fontWeight:"400",textDecoration:"underline"}}>linkedin.com/in/miraram/</a></p>
          <div className="intro-blurb">
          <h4 className='bullet-blurbs'>ux designer • xr researcher • fun-haver</h4>
                <p>
                Nice to meet you! I'm Mira; a UX designer with <b>6+ years</b> of experience designing <b>mobile, web,
                and XR applications</b> for clients ranging from non-profits to large corporations. My focus is on creating
                impactful solutions supported by in-depth user research and interesting visual interfaces.
                Regardless of the medium or product type, I take an iterative and user-focused approach to my work.
                I'm currently a UX Designer at <a className="about-link" target="_blank" href="https://www.campfire3d.com/">Campfire</a>.    
                </p>
                <p>
                I completed my <b>M.S. in Computer Science with a concentration in Digital Arts at
                Dartmouth College</b> in June 2022. My thesis research was focused on <b>social interaction in VR</b> and published in the Springer series <a className="about-link" target="_blank" href="https://link.springer.com/book/10.1007/978-3-031-62273-1">"Lecture Notes in Networks and Systems"</a>. I also previously
                graduated from Dartmouth in June 2020 with a B.A. in <b>Psychology and Digital Arts. </b> Shoot me an
                email if you'd like to chat!
                </p>
                {/* <p>
                When I'm not designing or researching, you can find me taking on all sorts of side quests. I
                </p> */}
        
                <p>Likes: drawing, video games, sleeping, cats, collecting trinkets</p>
                <p>Dislikes: also drawing, celery, when people use icons with inconsistent line weights  </p>


          </div>
        </div>
        <Footer />
      </div>
    )
  }

  normalRender() {
    return (
      <div>
        <HeaderBar/>
        <div className='about-me-page-layout'>
          <div className='about-me-flex-wrapper'>
            <div className='about-me-left'>
              <img style={{width:"18vw", maxWidth:'380px'}} src={miragif4}/>
              <div>
                <h1 style={{textTransform:"lowercase", marginTop:"10px"}}>Mira Ram</h1>
                <div className='about-me-rectangle'/>
                <p><a href="mailto:mira.ram@gmail.com" style={{color:"grey", fontWeight:"400",textDecoration:"underline"}}>mira.ram(@)gmail(.)com</a></p>

                <p><a href="https://www.linkedin.com/in/miraram/" target="_blank" style={{color:"grey", fontWeight:"400",textDecoration:"underline"}}>linkedin.com/in/miraram/</a></p>
              </div>
            </div>
            <div className='about-me-blurb'>
              <div className>
                <h4 className='bullet-blurbs'>ux designer • xr researcher • fun-haver</h4>
                <p>
                Nice to meet you! I'm Mira; a UX designer with <b>6+ years</b> of experience designing <b>mobile, web,
                and XR applications</b> for clients ranging from non-profits to large corporations. My focus is on creating
                impactful solutions supported by in-depth user research and interesting visual interfaces.
                Regardless of the medium or product type, I take an iterative and user-focused approach to my work.
                I'm currently a UX Designer at <a className="about-link" target="_blank" href="https://www.campfire3d.com/">Campfire</a>.    
                </p>
                <p>
                I completed my <b>M.S. in Computer Science with a concentration in Digital Arts at
                Dartmouth College</b> in June 2022. My thesis research was focused on <b>social interaction in VR</b> and published in the Springer series <a className="about-link" target="_blank" href="https://link.springer.com/book/10.1007/978-3-031-62273-1">"Lecture Notes in Networks and Systems"</a>. I also previously
                graduated from Dartmouth in June 2020 with a B.A. in <b>Psychology and Digital Arts. </b> Shoot me an
                email if you'd like to chat!
                </p>
                {/* <p>
                When I'm not designing or researching, you can find me taking on all sorts of side quests. I
                </p> */}
        
                <p>Likes: drawing, video games, sleeping, cats, collecting trinkets</p>
                <p>Dislikes: also drawing, celery, when people use icons with inconsistent line weights  </p>

              </div>
            </div>
          </div>
          {this.props.imageDecos}
        </div>
        <Footer />
      </div>
    )
  }
}

export default AboutMe;
