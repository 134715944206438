// libraries
import React from 'react';
import { checkMobile } from "../../Styles/ScreenCalcs";

import HeaderBar from '../../Components/HeaderBar';
import { projectDetailData } from './ProjectDetailPageComponents/ProjectDetailData';
import ProjectDetailListComponent from './ProjectDetailPageComponents/ProjectDetailListComponent';
import ScreenWithCaptionList from './ProjectDetailPageComponents/ScreenWithCaptionComponent';
import ProjectAnnouncementComponent from './ProjectDetailPageComponents/ProjectAnnouncementComponent';
import { ZoomableImg } from './ProjectDetailPageComponents/ZoomableImage';

import ScrollButton from '../../Components/ScrollButton';
// assets
import secondary_research from '../../assets/flourish/secondary_research.png';
import primary_research from '../../assets/flourish/primary_research.png';

import analysis_1 from '../../assets/flourish/analysis_1.png';
import analysis_2 from '../../assets/flourish/analysis_2.png';
import analysis_3 from '../../assets/flourish/analysis_3.png';
import analysis_4 from '../../assets/flourish/analysis_4.png';

import specifications from '../../assets/flourish/specifications.png';
import final_product from '../../assets/flourish/flourish_frame.png';

// css
import './ProjectDetailPageComponents/ProjectDetailPage.css';
import './Unmasked.css'
import { Link } from 'react-router-dom';
import Footer from './ProjectDetailPageComponents/Footer';


class FlourishProjectPage extends React.Component {
  constructor(props) {
    super(props)
    this.data = projectDetailData;
    this.background = '/assets/flourish_banner_nowords.png';
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.accent_color = '#5A75FC'
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  renderTags(tags) {
    let tag = tags.map((t, index) => {
      return (
        <div className='project-detail-tag-box font-medium-small' style={{backgroundColor:this.accent_color}}>
          <Link to={"/work?tag=" + t} style={{color:'#FFFFFF', textDecoration:'none'}}>{t}</Link>
        </div>
      )
    });
    return tag;
  }

  updateWindowDimensions() {
    this.setState({ width: document.documentElement.clientWidth, height: window.innerHeight });
  }

  getBackgroundSize() {
    if(this.state.width < 720) {
      return 'contain'
    }
    return '100vw'
  }

  render() {
    return (
      <div>
        <ScrollButton />
        <HeaderBar colorset="dark"/>
        <div className={'project-detail-page-banner'} style={{backgroundImage: `url(${this.background})`}}/>
        <div className='project-detail-page-layout'>
          {/* Title */}
          <h1 className="h1">Flourish</h1>
          <h2 className="h2">
          <a target="_blank" href="https://www.flourish.care/" style={{color: this.accent_color}}> Flourish</a> is an app designed to help patients with multi-symptom chronic illnesses better track,
          visualize, and communicate their symptoms and triggers over time. <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhrSuc8tufMMLZy93uC0hPb0axIJr7FdStvDNDrI_OgefIjA/viewform" style={{color: this.accent_color}}>Sign up</a> for the iPhone beta.
          </h2>

          {/* Tags */}
          <div className='project-detail-tag-layout'>
            {this.renderTags(["UI/UX", "Graphic Design", "Illustration"])}
          </div>

          {/* Background */}
          <h2 className='h2'>Background</h2>
          <p className='p'>
          Flourish was born out of a project created by myself and 3 other designers as a
          capstone project for the
          <a target="_blank" href="https://www.dartmouth.edu/sdc/" style={{color: this.accent_color}}> Senior Design Challenge</a>.
          Throughout this project, we were
          partnered with client organization Suffering the Silence and worked closely with
          client and patient Ari Paulsen, who presented us with our problem: design a way to
          help patients with multi-symptom chronic illnesses to better track and visualize their
          symptoms, triggers, and treatments.
          </p>
          <p className='p'>
          From January to March 2020, we conducted primary and secondary research, where we
          interviewed 46 patients and providers. From March to June 2020, we created and iterated on
          wireframes and prototypes in Figma prototypes while conducting rapid user testing. By the
          end of those 5 months, we had created a baseline for Flourish. The app is now in development while I continue to serve
          as the lead designer.
          </p>

          {/* Graphical list idk */}
          <ProjectDetailListComponent
            color={this.accent_color}
            titles={["Time frame", "Role", "Skills", "Team"]}
            textFields={["January – March 2020 \n(5 mo)",
                         "Lead Designer",
                         "User Research\nUser Interviewing\nData Analysis\nRapid Iteration\nWireframing\nPrototyping\nFigma",
                         "Mira Ram\nCallie Page\nSia Peng\nCarson Levine"]}
          />

          {/* Chronic Illness Primer */}
          <h2 className='h2'>Chronic Illness Primer</h2>
          <h3 className='h3'>At least 133 million Americans (more than 40% of the population)
          have at least one chronic illness.</h3>
          <p className='p'>
          Chronic illnesses are on the rise. According to the National Health Council, at least
          133 million Americans (more than 40% of the population) have at least one chronic illness.
          There are many potential causes of chronic illnesses, but the effects can be catastrophic —
          for the individual affected, the costs on our healthcare system, and the general wellness of our
          communities. The particular focus of this project is on relatively rare, poorly understood chronic
          illnesses such as Ehlers-Danlos Syndrome (EDS), Mast Cell Activation Syndrome (MCAS), and Fibromyalgia.
          </p>
          <br/>

          <h3 className='h3'>These illnesses are under-researched, under-recognized, and leave patients with a large
          cognitive load when hoping to track their health data.</h3>
          <p className='p'>
          These illnesses are under-researched and doctors remain largely mystified by the distinct patient profile of
          those affected disproportionately by chronic illness. Researchers like Dr. Sharon Meglathery have noticed a “silent
          epidemic” of co-occurring syndromes that affect mostly vibrant young people (predominantly women), with
          predispositions towards artistic genius, gender and sexual fluidity, and psychiatric disorders.
          </p>

          <p className='p'>
          Suffering the Silence (STS) is a 501(c)3 organization dedicated to leveraging the power of art, media, and
          storytelling to raise awareness around the life experiences of people living with chronic illnesses and
          disability.” Their goal in partnering with the Senior Design Challenge was to get closer to creating a
          symptom mapping app for patients with complex chronic illnesses. The current step in this process will be
          designing effective graphing and other visual tools to portray the data collected. <b>This data can empower
          patients to take ownership over their healing and provide accurate, personalized, quickly-accessible
          information for providers.</b>
          </p>

          <ProjectAnnouncementComponent
            color = {this.accent_color}
            title = "THE GOAL"
            text = {`How might we empower patients to take control of their health and
            facilitate better communication with their doctors?`}
          />

          {/* Problem Statement */}
          <h2 className='h2'>Problem Statement</h2>
          <h3 className='h3'>Patients need a simple way to keep track of their health data</h3>
          <p className='p'>
          According to Centers for Disease Control and Prevention, 6 in 10 adults in the U.S. have chronic
          conditions. This number is still on the rise, but the research has not kept up with the increase of
          chronic illnesses, especially for rare diseases like Ehlers-Danlos Syndrome (EDS). The chronic illness
          community has been historically under-recognized, under-studied, and under-served. Health providers do not
          have enough research information and resources to diagnose and treat patients with complex chronic
          conditions. <b>As a result, patients often have to take their health into their own hands.</b>
          </p>
          <p className='p'>
          With so many symptoms and triggers, health management can be an overwhelming task and take a mental toll.
          However, existing solutions in the market do not adequately meet their specific needs. Patients need a simple
          way to keep track of their health data, which can not only help them take control of their health but also
          better communicate with doctors.
          </p>
          <br/>

          {/* Secondary Research */}
          <h2 className='h2'>Secondary Research</h2>
          <p className='p'>
          We began our journey to understand the struggles of chronic illness patients by examining secondary
          resources. Through the patient stories we heard in films, TED talks, and personal blogs alike, we began to
          empathize with our often unheard and under-represented target audience. We additionally learned through
          medical resources and peer-reviewed scientific papers that many of the illnesses our audience suffers from
          are under-researched.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={secondary_research} src={secondary_research} />
          </div>


          {/* Primary Research */}
          <h2 className='h2'>Primary Research</h2>
          <p className='p'>
          After completing our secondary research, we began conducting interviews with stakeholders in the
          chronic illness community. We began by creating an interest survey for patients that our client
          liaison, Ari Paulsen, would send out to chronic illness communities and group chats she had access
          to. From there, we set up phone or video interviews with patients who were able to meet with us and
          sent out informational questionnaires to the remainder.
          </p>

          <p className='p'>
          The breakdown of our interviewees consists of 24 chronic illness patients, 3 families, 3 doctors,
          2 physical therapists, and 2 designers of similar products. We received 27 responses to our questionnaire.
          </p>

          <p className='p'>
          One of the designers we interviewed, Logan Merriam, designed and developed the mobile app FlareDown, which
          is currently viewed as the best generic tracking app for chronic illness.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={primary_research} src={primary_research} />
          </div>

          {/* Analysis Methods */}
          <h2 className='h2'>Analysis Methods</h2>
          <p className='p'>
          We used a wide variety of analysis methods in order to distill our findings into our key insights:
          </p>

          <h3 className='h3'>Personas</h3>
          <p className='p'>
          In creating personas based on our interviewees, we identified each persona by their gender, age, severity
          of illness, diagnosis experience, and how they were currently managing their symptoms to reflect the
          demographics and often vocalized concerns of patients.
          </p>

          <p className='p'>
          From these personas, we found that patients with chronic illness experience different levels of mobility
          based on the severity of their conditions. However, even functional patients experience fatigue and sometimes
          have to take a leave from their work. As a result, the main barrier to consistent tracking is the time and
          energy commitment.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={analysis_1} src={analysis_1} />
          </div>

          <h3 className='h3'>Journey Map</h3>
          <p className='p'>
          In mapping a chronic illness patient’s journey from the first symptom appearance to stabilization, we
          discovered one noteworthy pain point: the physical and emotional toll of getting a diagnosis drains
          patients of their energy, meaning that tracking is often sacrificed despite its potential benefits.
          </p>

          <p className='p'>
          To better investigate this area, we created another journey map of a patient’s experience on a single day.
          Even within a day, we found that a patient have very limited energy that may not even sustain their daily
          routine activities, which can further impede them from burdensome tracking.
          </p>
          <br></br>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={analysis_2} src={analysis_2} />
          </div>

          <br></br>
          <br></br>
          <h3 className='h3'>Stakeholder Map</h3>
          <p className='p'>
          On the positive side, we found that patients receive substantial support from chronic illness
          (“spoonie”) communities, both online and offline.
          </p>

          <p className='p'>
          However, we also found that patients with chronic illness often have to navigate extremely complex
          healthcare systems. They may need to consult many different doctors before getting a diagnosis. Even after
          the diagnosis, they cannot rely on one provider, but rather must “build a team.”
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={analysis_3} src={analysis_3} />
          </div>
          <br></br>

          <h3 className='h3'>Empathy Map</h3>
          <p className='p'>
          We found that patients who struggle to get a diagnosis for their illness find dealing with the healthcare system
          extremely frustrating. They think that doctors fail to understand their feelings and do not adequately
          do their jobs, leading to a lack of trust.
          </p>

          <p className='p'>
          On the other hand, doctors find it hard to grasp patient’s qualitative descriptions. They believe that they
          have applied all the knowledge they learned from medical school and did all the necessary tests. Their
          pain point for diagnosing chronic illness is the lack of prior knowledge and existing research to refer to.
          </p>

          <br></br>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={analysis_4} src={analysis_4} />
          </div>
          <br></br>

          {/* Insights */}
          <h2 className='h2'>Insights</h2>
          <p className='p'>
          From our research analysis, we extracted five key insights:
          </p>

          <h3 className='h3'> <span style={{color: this.accent_color, fontWeight:500}}>#01:</span> Many different rare chronic illnesses share common patient profiles,
          symptoms, and triggers.</h3>
          <p className='p'>
          We found that many patients across different conditions share a similar pathophysiology —
          certain symptoms and triggers were commonly mentioned by interviewees no matter their
          medical backgrounds. Our interviewees reported high incidences of co-morbidity — for example,
          the majority of patients suffering from Ehlers-Danlos also experienced POTS and MCAS. <b>How might we
          create a solution that isn't limited to a single illness?</b>
          </p>
          <br/>

          <h3 className='h3'> <span style={{color: this.accent_color, fontWeight:500}}>#02:</span> There is extreme variation even within one person’s experience of a single illness.</h3>
          <p className='p'>
          Even for one person, an illness can feel vastly different from day to day and the relevant
          symptoms and triggers shift over time. Because of the varied ways even one illness can present in
          different people, not to mention high rates of co-infection that add further nuance to the symptoms and
          triggers that spoonies experience, our group believes that customization will be a key feature of our solution.
          <b> How might we enable patients to track their unique and varied symptoms? How might we quantify qualitative symptoms?</b>
          </p>
          <br/>

          <h3 className='h3'> <span style={{color: this.accent_color, fontWeight:500}}>#03:</span> A lack of understanding and awareness about
          chronic illness in the medical community means that many struggle to get an appropriate diagnosis, leading to
          mistrust and disconnect between patients and doctors.</h3>
          <p className='p'>
          Most of our interviewees started presenting with symptoms in their childhood or adolescence, but the
          poorly understood nature of their illnesses and lack of definitive diagnostic tests often lead their
          doctors to conclude that their symptoms are psychosomatic. There are several barriers between doctors and
          patients when it comes to patients, including lack of time for appointments or gaps in doctor and patient
          language. <b>How might we bridge the gap between doctors and patients?</b>
          </p>
          <br/>

          <h3 className='h3'> <span style={{color: this.accent_color, fontWeight:500}}>#04:</span> To doctors, data speaks louder than
          words. Quantifying and visualizing symptoms may be a key step to better facilitate communication.</h3>
          <p className='p'>
          Many patients bring supplementary notes for their doctor to read, but if they require more than
          3-5 minutes to read they are likely to be ignored. Our interviewees found that numbers and graphs
          are the languages that doctors understand best, but it can be challenging to quantify their symptoms.
          <b>How might we leverage the power of data to help patients to build credibility with their doctors? How
          might we aggregate comprehensive longitudinal data for doctors and researchers to better understand
          chronic illness?</b>
          </p>
          <br/>

          <h3 className='h3'> <span style={{color: this.accent_color, fontWeight:500}}>#05:</span> Tracking symptoms is taxing and may remind patients of
          the overwhelming toll of living with chronic illness.</h3>
          <p className='p'>
          Many of our interviewees mentioned that they think tracking their symptoms would be beneficial, but they
          themselves do not track. Tracking requires a huge time commitment and can be quite taxing for patients.
          <b>How might we ease the burden of tracking by making it frictionless and perhaps even delightful?</b>
          </p>

          {/* Specifications*/}
          <h2 className='h2'>Specifications</h2>
          <p className='p'>
          Based on our insights, we generated the following specifications as criteria for an ideal solution.
          </p>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <ZoomableImg key={specifications} src={specifications} />
          </div>

          {/* Final Product*/}
          <h2 className='h2'>Final Product</h2>
          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
            <img style={{maxWidth:'60vw', display:'inline-block'}} src={final_product}></img>
            <p className='p' style={{fontSize:"10px"}}>The current version of the app (2022)</p>
          </div>
          <p className='p'>
          While the app looks much different now after months of usability testing and iteration, this was the
          version we landed on at the end of the Senior Design Challenge in June 2020, and the version we started with when
          the project transferred to the DALI lab for its development. I hope to one day be able to share more information
          with you about the latest direction Flourish has taken and how we got here, but for now, please check out
          the Figma prototype below or take a look at:
          </p>
          <ul className='ul'>
            <li className='li'><a target="_blank" href="https://drive.google.com/file/d/18XXE7_qOYzpyuyLYzHsEWOjaQmLpvh4b/view" style={{color: this.accent_color}}>The detailed final report</a></li>
            <li className='li'><a target="_blank" href="https://docs.google.com/presentation/d/1DU9FQeB7QGNKeOLfZIhmaPDaNSOJFemE-AecJAooFu4/edit?usp=sharing" style={{color: this.accent_color}}>Final presentation slides</a></li>
            <li className='li'><a target="_blank" href="http://flourish.care/" style={{color: this.accent_color}}>Flourish’s website (contact us for a demo of the current app!)</a> </li>
          </ul>
          <br/>

          <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <iframe
              style={{border: '2px solid rgba(0, 0, 0, 0.1)', borderRadius:'10px'}}
              width="600"
              height="1000"
              src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FSmsqCr4bjI6c8UOsBuCcP2%2FFlourish%3Fchrome%3DDOCUMENTATION%26kind%3D%26node-id%3D1%253A3158%26scaling%3Dmin-zoom"
              allowfullscreen>
            </iframe>
          </div>

          {/* Challenges */}
          <br/>
          <h2 className='h2'>Challenges & Takeaways</h2>
          <p className='p'>
          During and after our 5-month sprint to design Flourish, we faced many challenges:
          </p>

          <p className='p'>
          <b style={{fontWeight:600}}>User Research </b> <br/>
          Prior to my work for Flourish and the Senior Design Challenge, I did have some experience with user research. However,
          this project was my time conducting such in-depth research for a product. In previous projects, I’d never spent more than 3 weeks
          on user research. For this project, we devoted 3 months to user research and continued to conduct usability testing throughout
          the process. This was a very eye-opening experience for me, and has deeply affected the way I design in all my work since starting Flourish.
          </p>

          <p className='p'>
          <b style={{fontWeight:600}}>Listening to the users </b> <br/>
          There were many moments throughout the project where my team would disagree on a design direction, whether it be a feature, a UX flow, or
          UI decision. In these situations, we would always return to our user research insights, and in some cases even call upon one of our
          previous user testers for feedback.
          </p>

          <p className='p'>
          <b style={{fontWeight:600}}>Adapting to the pandemic </b> <br/>
          Halfway through the project, we transitioned to a remote workflow with the outbreak of COVID-19. Because we ended up deciding on a
          mobile app solution, our work was well-suited for remote teamwork, but it was still a change that required quite a bit of restructuring
          for both our team and the Senior Design Challenge course as a whole.
          </p>







          <p></p>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default FlourishProjectPage;
