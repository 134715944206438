// libraries
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from "react-transition-group";

// css
import './LandingPage.css';
import './LandingPageCard.css';
import './LandingPageCardMobile.css';

export class LandingPageCard extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.isMobile) return this.mobileRender()
    else return this.normalRender();
  }

  mobileRender() {
    return (
      <div id={this.props.cssId} className='landing-page-card-mobile-wrapper'>
        <img className='landing-page-card-mobile-image' src={this.props.mobileImage}/>
        <MobileBlurb
          title={this.props.title}
          blurb={this.props.blurb}
          projectButtonText={this.props.projectButtonText}
          protoButtonText={this.props.protoButtonText}
          cssId={this.props.cssId}
          href={this.props.href}
          color={this.props.color}
          />
      </div>
    )
  }

  normalRender() {
    return (
      <div id={this.props.cssId} className={'landing-page-card-wrapper'}>
        <div className='landing-page-card-flex-wrapper'>

          {this.props.isImageOnRight ? null :
            <div className={'landing-page-card-flex-component'}>{this.props.image}</div>
          }

          <ProjectBlurb
            title={this.props.title}
            subtitle={this.props.subtitle}
            blurb={this.props.blurb}
            projectButtonText={this.props.projectButtonText}
            protoButtonText={this.props.protoButtonText}
            cssId={this.props.cssId}
            href={this.props.href}
            color={this.props.color}
          />
          {this.props.isImageOnRight ?
            <div className={'landing-page-card-flex-component'}>{this.props.image}</div>
            : null
          }
        </div>
        {this.props.imageDecos}
      </div>
    )
  }
}

LandingPageCard.propTypes = {
  // array of absolutely positioned images inside the card (useful for background decoration and such)
  imageDecos: PropTypes.array,

  // self explanatory
  title: PropTypes.string,
  blurb: PropTypes.string,
  projectButtonText: PropTypes.string,
  protoButtonText: PropTypes.string,

  // big image to complement the text
  image: PropTypes.element,

  // orientation? image on the right?
  isImageOnRight: PropTypes.bool,

  // css id
  cssId: PropTypes.string
}

// ================ PROJECT BLURB ========================

class ProjectBlurb extends React.Component {
  render() {
      return(
          <div id={this.props.cssId} className="project-blurb">
              {/* title */}
              <h2> {this.props.title} </h2>

              <div id={this.props.cssId} className="project-blurb-rectangle" style={{backgroundColor: this.props.color}}></div>

              <h4>{this.props.subtitle}</h4>
              {/* description */}
              <p>
                <b>{this.props.title}</b> {this.props.blurb}
              </p>


              {/* buttons */}
              <div id={this.props.cssId} className='project-buttons'>
                <a href={this.props.href}>
                    <div id={this.props.cssId} class='project-button' style={{backgroundColor: this.props.color}}>
                      {this.props.projectButtonText}
                    </div>
                  </a>
              </div>
           </div>

      )
  }
}

ProjectBlurb.propTypes = {
  // self explanatory
  title: PropTypes.string,
  blurb: PropTypes.string,
  projectButtonText: PropTypes.string,
  protoButtonText: PropTypes.string,
  // css id
  cssId: PropTypes.string,
}


class MobileBlurb extends React.Component {
  constructor(props){
    super(props)
  }
  render() {
      return(
          <div id={this.props.cssId} className="mobile-blurb">
              {/* title */}
              <h2> {this.props.title} </h2>

              <div id={this.props.cssId} className="project-blurb-mobile-rectangle" style={{backgroundColor: this.props.color}}></div>

              {/* description */}
              <p>
                <b>{this.props.title}</b> {this.props.blurb}
              </p>

              {/* buttons */}
              <div id={this.props.cssId} className='project-buttons'>
                <a href={this.props.href}>
                    <div id={this.props.cssId} class='project-button' style={{backgroundColor: this.props.color}}>
                      {this.props.projectButtonText}
                    </div>
                  </a>
              </div>
           </div>

      )
  }
}


export class FinalCard extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.isMobile) return this.mobileRender()
    else return this.normalRender();
  }

  mobileRender() {
    return (
      <div className='landing-page-mobile-final-container'>
        <div className='landing-page-card-mobile-wrapper-final'>
          <img className={'top-left-final-image-mobile'} src={this.props.image1}/>
          <img className={'bot-left-final-image-mobile'} src={this.props.image2}/>
          <img className={'top-right-final-image-mobile'} src={this.props.image3}/>
          <h2>Everything Else</h2>
          <div id={this.props.cssId} className="project-blurb-mobile-rectangle" style={{backgroundColor: this.props.color}}></div>
          <div style={{textAlign: 'center', paddingLeft: '10vw', paddingRight: '10vw', color: 'grey', paddingTop:'1vh'}}>
            <p>Interested in seeing more? Explore my other projects ranging from fully developed VR experiences to illustrated animations</p>
          </div>
          <div id={this.props.cssId} className='project-buttons'>
            <a href={this.props.href} style={{textDecoration: 'None'}}>
                <div id={this.props.cssId} class='project-button' style={{backgroundColor: this.props.color, textDecoration: 'None'}}>
                  {this.props.projectButtonText}
                </div>
              </a>
          </div>
        </div>
      </div>
    )
  }

  normalRender() {
    return (
      <div className='landing-page-final-container'>
        <div className={'top-left-final-image'}>
          {this.props.image1}
        </div>
        <div className={'bot-left-final-image'}>
          {this.props.image2}
        </div>
        <div className={'top-right-final-image'}>
          {this.props.image3}
        </div>
        <div className={'bot-right-final-image'}>
          {this.props.image4}
        </div>
        <div className={'landing-page-card-final'}>
          <h2>Everything Else</h2>
          <div id={this.props.cssId} className="project-blurb-rectangle" style={{backgroundColor: this.props.color, marginTop:'10px',marginBottom:'10px'}}></div>
          <div className="landing-page-card-final-blurb">
            <p>Interested in seeing more? Explore my other projects ranging from fully developed VR experiences to illustrated animations</p>
          </div>
          <div id={this.props.cssId} className='project-buttons'>
            <a href={this.props.href}>
                <div id={this.props.cssId} class='project-button' style={{backgroundColor: this.props.color}}>
                  {this.props.projectButtonText}
                </div>
              </a>
          </div>
        </div>
      </div>
    )
  }
}