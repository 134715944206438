export const projectData = [
    {
        title: "Campfire",
        subtitle: "2022 - 2024",
        tags: ["Featured","UI/UX", "XR","3D"],
        description: "The update of a multi-platform design review experience for an enterprise 3D software that led to a customer success story.",
        imageSrc:"covers/project_campfire.gif",
        link:"/campfire",
    },
    // {
    //     title: "Ocular AI",
    //     subtitle: "2024",
    //     tags: ["Featured","UI/UX","Graphic Design"],
    //     description: "Defining the brand for a Y-combinator backed enterprise AI software.",
    //     imageSrc:"covers/project_campfire.gif",
    //     link:"/ocular",
    // },
    {
        title: "Flourish",
        subtitle: "2020",
        tags: ["Featured", "UI/UX", "Graphic Design"],
        description: "Designing an easy to use symptom, trigger, and treatment tracker for patients with chronic illnesses.",
        imageSrc:"covers/project_flourish.png",
        link:"/flourish",
    },
    {
        title: "Unmasked",
        subtitle: "2020",
        tags: ["Featured","UI/UX", "Graphic Design"],
        description: "The redesign of a mental health-centered app for college students that led to increased usage and press recognition.",
        imageSrc:"covers/project_unmasked.png",
        link:"/unmasked",
    },
    {
        title: "Veracity Research",
        subtitle: "2021 - 2022",
        tags: ["Featured", "XR", "Development", "3D","UI/UX"],
        description: "Researching avatar movement veracity in VR, advised and funded by a gift from Meta Research. Presented at the 2024 SAI Computing Conference.",
        imageSrc:"covers/project_silvr.png",
        link:"/silvr",
    },
    {
        title: "By Water",
        subtitle: "2022",
        tags: ["Featured","Illustration","Motion"],
        description: "Creating a multimedia animation for an experimental short film that would premiere at Sundance Film Festival.",
        imageSrc:"covers/project_by_water.gif",
        link:"/bywater",
    },
    {
        title: "Soundboard",
        subtitle: "2021",
        tags: ["Featured", "UI/UX", "Development", "Graphic Design", "Illustration"],
        description: "Designing and developing an app to improve the process of finding sound samples and musical inspiration for sound designers.",
        imageSrc:"covers/project_soundboard.png",
        link:"/soundboard",
    },
  
    {
        title: "CBW",
        subtitle: "2020",
        tags: ["UI/UX", "Graphic Design"],
        description: "Desiging a digital banking experience for an upcoming merchant acquiring app.",
        imageSrc:"covers/project_cbw.png",
        link:"/cbw",
    },
    {
        title: "ProducePay",
        subtitle: "2021",
        tags: ["UI/UX"],
        description: "Designing and shipping a new produce marketplace to connect growers and distributors.",
        imageSrc:"covers/project_producepay.png",
        link:"/producepay",
    },
    {
        title: "Unleashed VR",
        subtitle: "2019",
        tags: ["Featured","XR", "Development", "3D", "Motion"],
        description: "Designing a set of threaputic VR experiences for Meta Quest.",
        imageSrc:"covers/project_unleashed.gif",
        link:"/unleashed",
    },

    {
        title: "Four Seasons AR",
        subtitle: "2019",
        tags: ["XR", "Development", "3D", "Motion"],
        description: "Modeling a mobile AR artistic experience.",
        imageSrc:"covers/project_4_seasons.gif",
        link:"/fourseasons",
    },
    {
        title: "K Health",
        subtitle: "2019",
        tags: ["Featured", "UI/UX", "Graphic Design"],
        description: "The redesign of a therapy telehealth app that contributed to its subsequent acquisition.",
        imageSrc:"covers/project_trusst.png",
        link:"/trusst",
    },
    {
        title: "DartHub",
        subtitle: "2018",
        tags: ["UI/UX"],
        description: "Designing critical features for Dartmouth's web student portal.",
        imageSrc:"covers/project_darthub.png",
        link:"/darthub",
    },
    {
        title: "DALI 3D Model",
        subtitle: "2018",
        tags: ["3D"],
        description: "A 3D Model of Dartmouth's DALI Lab modeled and rendered in Autodesk Maya.",
        imageSrc:"covers/project_dali_3D.png",
        link:"/dali3D",
    },
    {
        title: "Perfume Brand",
        subtitle: "2019",
        tags: ["Graphic Design"],
        description: "Website and branding for a fictitious perfume company.",
        imageSrc:"covers/project_perfume.png",
        link:"/perfume",
    },
    {
        title: "Print",
        subtitle: "2018 - 2022",
        tags: ["Graphic Design", "Physical"],
        description: "An assortment of print layouts I've created for various projects.",
        imageSrc:"covers/project_print.png",
        link:"/print",
    },
    {
        title: "App Illustration",
        subtitle: "2020 - 2022",
        tags: ["Illustration", "UI/UX", "Graphic Design"],
        description: "An assortment of custom illustrations I've created for mobile and web app projects.",
        imageSrc:"covers/project_app_illust.png",
        link:"/appillust",
    },
    {
        title: "Language Data",
        subtitle: "2020",
        tags: ["Motion", "Development", "Graphic Design", "Illustration"],
        description: "Researching and visualizing language shift over generations.",
        imageSrc:"covers/project_language.gif",
        link:"/language",
    },
    {
        title: "Hobby Illustration",
        subtitle: "2018 - 2022",
        tags: ["Illustration"],
        description: "An assortment of illustrations I've done for fun!",
        imageSrc:"covers/project_hobby_illust.png",
        link:"/hobbyillust",
    },
    {
        title: "Comics",
        subtitle: "2018",
        tags: ["Illustration"],
        description: "Depicting college struggles through comics.",
        imageSrc:"covers/project_comics.png",
        link:"/comics",
    },
    {
        title: "The Dartmouth",
        subtitle: "2018 - 2020",
        tags: ["Motion", "Illustration", "Graphic Design"],
        description: "Designing and animating 'top snaps' for the Dartmouth newspaper's Snapchat story.",
        imageSrc:"covers/project_snapchat.gif",
        link:"/snapchat",
    },
    {
        title: "Esai's Table",
        subtitle: "2019 - 2020",
        tags: ["Motion"],
        description: "Animating stage projections for an Off-Broadway production centering on the destiny of three young black men. ",
        imageSrc:"covers/project_esai.png",
        link:"/esai",
    },
    {
        title: "Theatre VR",
        subtitle: "2021",
        tags: ["XR", "Development", "3D","UI/UX"],
        description: "Designing and modeling a multi-user VR platform for the practice and performance of Black theatre in a culturally inclusive environment.",
        imageSrc:"covers/project_theatrevr.png",
        link:"/theatre",
    },
    {
        title: "Ocean Prism",
        subtitle: "2021",
        tags: ["Physical", "3D"],
        description: "A concept for a physical art installation featuring suspended pyramids in space.",
        imageSrc:"covers/project_ocean.png",
        link:"/ocean",
    },
    {
        title: "Celestial Gate",
        subtitle: "2021",
        tags: ["Physical", "3D"],
        description: "A pavilion concept designed for the Hyde Park Winter Wonderland. ",
        imageSrc:"covers/project_celestial.png",
        link:"/celestial",
    },
    {
        title: "Circle of Life",
        subtitle: "2021",
        tags: ["Physical", "3D"],
        description: "A laser-cut piece llustrating the transition from life to death through a tiger’s stripes and bones.",
        imageSrc:"covers/project_circle.png",
        link:"/circle",
    },
    {
        title: "Firefly Ruins Shaders",
        subtitle: "2021",
        tags: ["3D", "Development","Motion"],
        description: "Implementing various graphics algorithms to render the ruins of a mysterious island. Algorithms implemented include a basic ray tracer, sky sphere, environment mapper, and more.",
        imageSrc:"covers/project_ruins.gif",
        link:"/firefly",
    },

    {
        title: "Mesh Subdivider",
        subtitle: "2021",
        tags: ["3D", "Development"],
        description: "A simple mesh subdivider algorithm.",
        imageSrc:"covers/project_mesh.gif",
        link:"/mesh",
    },

    {
        title: "GLSL Shader",
        subtitle: "2021",
        tags: ["3D", "Development"],
        description: "Simple lambertian and phong shaders implemented with C++ and OpenGL.",
        imageSrc:"covers/project_shader.gif",
        link:"/glsl",
    },

    {
        title: "Position-Based Fluid",
        subtitle: "2021",
        tags: ["Development","Motion"],
        description: "Implementing a 2007 Position Based Fluids paper.",
        imageSrc:"covers/project_fluid.gif",
        link:"/position",
    },


]