import React from "react";

import '../Styles/Fonts.scss';
import "./SlidingButton2.css";

export default class SlidingButton2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false
    }
  }

  color() {
    if (this.props.color) {
      return this.props.color;
    }
    return "default";
  }

  colorClass(className) {
    return className + " " + this.color();
  }

  addTransitionStyle = () => {
    if(!this.state.hovered){
      this.setState({hovered: true})
    }
  }

  render() {
    let anim_bg = this.state.hovered ? " st2-anim-bg" : "";
    let anim_translate = this.state.hovered ? " st2-anim-translate" : "";
    let anim_color = this.state.hovered ? " st2-anim-color" : "";
    return (
        <a href={this.props.href} className={"sliding-button link " + this.color() + anim_bg}>
          <div onMouseEnter={this.addTransitionStyle} className={'sliding-button-text-container' + anim_translate}>
            <div className={this.colorClass("left-text")}><img className={this.colorClass("left-icon")} src={this.props.left_icon}/></div>
            <div className={this.colorClass("middle-text" + anim_color)}>{this.props.middle_text}</div>
            <div className={this.colorClass("right-text")}><img className={this.colorClass("right-icon")} src={this.props.right_icon}/></div>
          </div>
        </a>
    )
  }
}